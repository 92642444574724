/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';

import { Helmet } from "react-helmet";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/FullDashboardLayout";
import DashboardNavbarNotLooged from "examples/Navbars/DashboardNavbarNotLooged";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftInput from "components/SoftInput";

// @mui material components
import Card from "@mui/material/Card";


import Grid from "@mui/material/Grid";

import Parser from 'html-react-parser';
import SoftButton from "components/SoftButton";
//import { AutoComplete } from 'primereact/autocomplete';
import Autocomplete from '@mui/material/Autocomplete';


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import team2 from "assets/images/team-2.jpg";

//import Modal from 'react-bootstrap/Modal';

// Data
//import membersTableData from "layouts/groups/data/membersTableData";

import SoftAvatar from "components/SoftAvatar";

// Images
import 'auction.css';
import TextField from '@mui/material/TextField';

import React, { useState, useEffect } from "react";
import WheelComponent from "react-wheel-of-prizes";
import { Wheel } from "react-custom-roulette";
import Modal from 'react-modal';
import FormSelect from 'react-bootstrap/FormSelect';
import { FaBackward } from "react-icons/fa";
import { FaPenSquare } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import team_player_2 from "assets/images/team-2.jpg";
import loader_img from "assets/images/loader.svg";
import ReactImageZoom from 'react-image-zoom';
import livelogo from "assets/images/livecricketkerala.png";
import {  Link } from "react-router-dom";


const SpinnerWrapStyle = styled('div')`
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
`;

const Spinner = () => (
  <img src={loader_img} class="zoom2" height="30"/>
)


const AuctionModalWrapper = styled('div')({
  position: 'relative',

  overflow:'auto',
 '& .player-dp': {
  margin : '0 auto',
  width: '300px',
  maxWidth: '95%',
  position: 'relative',
  'img': {
    width: '100%',
    maxWidth: '100%',
    borderRadius: '10px'
  },
  ' & .player-number' : {
    fontSize: '18px',
    position: 'absolute',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    lineHeight:'30px',
    color: '#fff',
    top: '0',
    right: '0',
    textAlign :'center',
    backgroundColor: 'coral'
  }
},
'& .player-name':{
  textAlign :'center',
  textTransform: 'uppercase',
  fontSize: '40px'
},
'& .close-btn' : {
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  fontSize: '30px',
  position: 'absolute',
  right: '-5px',
  top: '-10px',
  cursor: 'pointer',
},
'& .custom-select-wrap' : {
  border: '1px solid #d2d6da',
  cursor: 'pointer',
  position: 'relative',
  padding: '0 10px 3px',
  borderRadius: '8px',
  '&:after': {
    content: '""',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '6px 5px 0 5px',
    borderColor: '#000000 transparent transparent transparent',
    position: 'absolute',
    right: '10px',
    top: '15px'
    
  },
  'select': {
    cursor: 'pointer',
    padding: '5px',
    border: 0,
    outline: 0,
    backgroundColor: 'transparent',
    '-moz-appearance': 'none',
    '-webkit-appearance': 'none',
  },
  'select:focus': {
    outline: 'thin dotted',
  outline: '5px auto -webkit-focus-ring-color',
  'outline-offset': '-2px'
  }
}




 
});



const customStyles = {
  content: {
    left: '5%',
    top : '0',
    bottom : '0',
    width: '90%',
  },
  overlay: { zIndex: 1000 }
};
const panelStyle = {
  zIndex: 100000 // Set the desired z-index value
};


const customStyles1 = {
  content: {
    top: '40%',
    left: '50%',
    width: '75%',
    right: 'auto',
    bottom: 'auto',
    overflow : 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: { zIndex: 1000 }
};


const data = [
  { option: "0", style: { backgroundColor: "#c2db62", textColor: "white" } },
  { option: "1", style: { backgroundColor: "#5fbd5a", textColor: "white" } },
  { option: "3", style: { backgroundColor: "#c2db62", textColor: "white" } },
  { option: "4", style: { backgroundColor: "#5fbd5a", textColor: "white" } },
  { option: "5", style: { backgroundColor: "#c2db62", textColor: "white" } },
  { option: "6", style: { backgroundColor: "#5fbd5a", textColor: "white" } },
  { option: "7", style: { backgroundColor: "#c2db62", textColor: "white" } },
  { option: "8", style: { backgroundColor: "#5fbd5a", textColor: "white" } }

];



function SoldPlayer({ user_id, player_name, team_name, team_id, sold_amount, sl_no, captain_flag, noGutter }) {
  return (
    <SoftBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor="grey-100"
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <SoftBox width="100%" display="flex" flexDirection="column">

        <SoftBox mb={1} lineHeight={0}>
          <SoftTypography variant="caption" color="text">

            <SoftTypography variant="caption" textTransform="capitalize">
              {player_name} ({team_name})
              {
                // alert(captain_flag)
                (() => {
                  if (captain_flag == 0) {
                    return (<FaPenSquare onClick={() => window.helloComponent.onEditSellModal(sl_no, player_name, team_id, sold_amount, user_id)}></FaPenSquare>)
                  } else {
                    return (<span>&nbsp;(c)</span>)
                  }
                })()
              }

            </SoftTypography>

          </SoftTypography>
        </SoftBox>



      </SoftBox>
    </SoftBox>
  );
}

function UnsoldPlayer({ user_id, player_name, sl_no, profile_pic, cric_heroes_profile_link, noGutter }) {
  return (
    <SoftBox
      mb={noGutter ? 0 : 1}
    >
          <SoftTypography variant="caption" color="text">
            <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="capitalize">
            {sl_no}. {player_name} 
            </SoftTypography>
            &nbsp;
            <FaBackward title="Move to Pending" id="movependingbtn" onClick={() => window.helloComponent.onClickReverseUnSold(user_id)} ></FaBackward>
            
            <img src={loader_img} id="loadergif_unsold_pending" style={{display:'none'}} class="zoom2" height="30"/>

          </SoftTypography>
     
    </SoftBox>
  );
}
function PendingPlayer({ user_id, player_name, sl_no, profile_pic, cric_heroes_profile_link, noGutter ,onClickPlayerProfileToSell}) {
  return (
    <SoftBox
      mb={noGutter ? 0 : 1}
     
    >
            <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="capitalize" onDoubleClick={() => onClickPlayerProfileToSell(sl_no)}>
            {sl_no}. {player_name} 
            </SoftTypography>
    </SoftBox>
  );
}

function Team({ team_name, team_logo, current_wallet_balance,team_max_playes_count, current_players_count, players,max_bid_for_a_player, noGutter }) {
  var line_bg = "";
  if(current_players_count == team_max_playes_count)
  {
    line_bg = "info";
  }
  return (

    <SoftBox item  mb={4} xs={4} md={4} >
       
          

      <table width="100%" bgcolor="#c8c8c8">
                    <tbody>
                      <tr>
<td width="10%"><SoftAvatar
            src={team_logo ? (team_logo) : (team2)}
            alt="profile-image"
            variant="rounded"
            size="sm"
            shadow="sm"
          /></td>

          <td width="20%"><SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {team_name}
          </SoftTypography>
          </td>

          <td width="20%" fontSize="8px" size="sm">
          <SoftTypography variant="caption" size="sm">
          Wallet Balance:
          </SoftTypography>
          </td>
        <td width="10%">
        <SoftTypography variant="caption" fontWeight="medium">
              {current_wallet_balance}
            </SoftTypography>
        </td>

        <td width="20%" fontSize="8px" size="sm">
        <SoftTypography variant="caption" size="sm">
          Maximum bid for a player:</SoftTypography>
          </td>
        <td width="10%">
        <SoftTypography variant="caption" fontSize="18px">
              {max_bid_for_a_player}
            </SoftTypography>
        </td>

        <td width="20%">
        <SoftTypography variant="caption" size="sm">
        Current count:
        </SoftTypography>
          </td>
        <td width="10%">
        <SoftTypography variant="caption" fontWeight="medium">
              {current_players_count}
            </SoftTypography>
        </td>

      </tr></tbody>
      </table>




{
      (() => {
      const listitems = players;
      //console.log(listitems);
      //console.log("populating")
      if (typeof(listitems) != "undefined")
      {
      //console.log(listitems)
  const rows = [];
  for (let i = 0; i < listitems.length; i++) 
  {
    let captain_flag_name = "";
    //team_name,  team_logo, current_wallet_balance, current_players_count, players
      if(listitems[i].captain_flag=='1')
      {
        captain_flag_name = " (C)";
      }
      rows.push(<SoftTypography size="sm" style={{ fontSize: 14 }}>{i+1}. {listitems[i].full_name}{captain_flag_name}&nbsp;[{listitems[i].sl_no}]&nbsp;({listitems[i].auction_sold_amount})
      
      {
                // alert(captain_flag)
                (() => {
                  if (listitems[i].captain_flag == 0) {
                    return (<FaPenSquare onClick={() => window.helloComponent.onEditSellModal(listitems[i].sl_no, listitems[i].full_name, listitems[i].match_team_fk, listitems[i].auction_sold_amount, listitems[i].user_fk)}></FaPenSquare>)
                  } else {
                    return (<span>&nbsp;(c)</span>)
                  }
                })()
      }

      {
        //last_sold_status_flag

        (() => {
          if (listitems[i].last_sold_status_flag == 1) {
            return (<span style={{ color: 'red' }}>***</span>)
          }
        })()

      }
      
      </SoftTypography>);
  }
  return rows;
}

})()

}


    </SoftBox>
  );
}

function PopTeam({ team_name, team_logo, team_max_playes_count,current_wallet_balance, current_players_count, players,max_bid_for_a_player, noGutter }) {
  var line_bg = "";
  if(current_players_count == team_max_playes_count)
  {
    line_bg = "info";
  }
  return (
    <Grid xs={12} md={12}>
       
          <SoftAvatar
            src={team_logo ? (team_logo) : (team2)}
            alt="profile-image"
            variant="rounded"
            size="sm"
            shadow="sm"
          />

          <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {team_name}
          </SoftTypography>

         

        <SoftBox mb={1} lineHeight={0}>
          <SoftTypography variant="caption" color="text">
            Wallet Balance:&nbsp;&nbsp;&nbsp;
            <SoftTypography variant="caption" fontWeight="medium">
              {current_wallet_balance}
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={1} lineHeight={0}>
        <SoftTypography variant="caption" color="text">
          Maximum bid for a player:&nbsp;&nbsp;&nbsp;
          <SoftTypography variant="caption" fontWeight="medium">
            {max_bid_for_a_player}
          </SoftTypography>
        </SoftTypography>
        </SoftBox>

        <SoftBox mb={1} lineHeight={0} bgColor={line_bg}>
        <SoftTypography variant="caption" color="text">
          Current Count:&nbsp;&nbsp;&nbsp;
          <SoftTypography variant="caption" fontWeight="medium">
            {current_players_count}
          </SoftTypography>
          
           </SoftTypography>
           </SoftBox>





    </Grid>
  );
}



class AuctionSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value:'',items:['Apple', 'Banana', 'Cherry', 'Date', 'Elderberry'],org_items:['Apple', 'Banana', 'Cherry', 'Date', 'Elderberry'], x: false, sx: false, main_auction_details: { group_name: 'Group Name' }, spin_data: data ,team_max_playes_count: -1};
    window.helloComponent = this;

    this.autocompleteRef = React.createRef();
    this.allItems = ['Apple', 'Banana', 'Cherry', 'Date', 'Elderberry'];
    setInterval(this.getCurrentBid, 7000);
    //    this.setState({x: false,sx: false});

  }
  handleSelect = (value) => {
    // Set the input value using the reference
    this.autocompleteRef.current.setState({ value });
  };


  onClickPlayerProfileToSell  = (sl_no) => {
    
    this.setState({ enter_manually: 1 });
    setTimeout(() => {
      //document.getElementById('e_enter_manually').focus();
      const inputField = document.getElementById('e_enter_manually');
      if (inputField) {
        inputField.value = sl_no; // Set sl_no as the value
  
        // Trigger input event to simulate a change in the field
        const inputEvent = new Event('input', { bubbles: true });
        inputField.dispatchEvent(inputEvent);
  
        // Focus the input field
        inputField.focus();
  
        // Optionally, if you need to simulate form submission:
        const enterEvent = new KeyboardEvent('keypress', {
          key: 'Enter',
          code: 'Enter',
          keyCode: 13,
          charCode: 13,
          bubbles: true,
        });
  
        inputField.dispatchEvent(enterEvent); // Dispatch the Enter event
      }

    }, 700);


  };


  handleMenuVisibilityChange = (isOpen) => {
    if (isOpen) {
      // You can fetch suggestions here based on the current input value
      const inputValue = this.autocompleteRef.current.state.value;
      const filteredItems = this.allItems.filter(
        (item) => item.toLowerCase().includes(inputValue.toLowerCase())
      );
      // Set the list of items using the reference
      this.autocompleteRef.current.setState({ items: filteredItems });
    }
  };
  handleChange = (event, newValue) => {
    document.getElementById('a_match_team_id').focus();
  }

  handleHighlightChange = (event, newValue) => {
    console.log(newValue)
    this.updateCurrentBid(newValue);
  }


  search = (event) => {
    console.log(event.query.toLowerCase() )
    //this.setState([...Array(10).keys()].map(item => event.query + '-' + this.state.item));
    setTimeout(() => {

    let _filteredCountries =this.state.org_items;
    //console.log(_filteredCountries )

            if (!event.query.trim().length) {
                _filteredCountries = this.state.org_items;
            }
            else {
                _filteredCountries = _filteredCountries.filter((country) => {

                  country = ''+country;
                    //return country.toLowerCase().startsWith(event.query.toLowerCase());
                    return country.toLowerCase().includes(event.query.toLowerCase());
                });
            }

            this.setState({items:_filteredCountries
            });
          }, 250);
            //setFilteredCountries(_filteredCountries);


    console.log(this.state.items);

}



  onEditSellModal = (sl_no, player_name, team_id, sold_amount, user_id, e) => {
    this.setState({
      showEditSell: !this.state.showEditSell,
      e_selected_sl: sl_no,
      e_selected_player: player_name,
      e_team_id: team_id,
      e_selected_user_fk:user_id,
      e_sold_amount: sold_amount
    });

    setTimeout(
      () => document.getElementById('e_a_match_team_id').value = team_id,
      700
    );
    setTimeout(
      () => document.getElementById('e_a_sold_amount').value = sold_amount,
      700
    );

    //



  };

  //const [x, sx] = React.useState(false);

  stopSpin = e => {
    console.log("stoppppppppp", e);
    //sx(false);
    //alert(this.state.spin_data[this.state.prizeNo].player_name)

    //this.setState({optSmModal:false})
//alert(this.state.prizeNo+","+this.state.spin_data[this.state.prizeNo].option)
    this.setState({
      x: false, prizeNo: 1, showSell: !this.state.showSell,
      selected_sl: this.state.spin_data[this.state.prizeNo].option,
      //selected_new_player_id: this.state.spin_data[this.state.player_id].option,
      selected_new_player_id: this.state.spin_data[this.state.prizeNo].player_id,
      selected_player: this.state.spin_data[this.state.prizeNo].player_name,
      selected_player_pic : this.state.spin_data[this.state.prizeNo].player_pic,
      selected_cric_img : this.state.spin_data[this.state.prizeNo].cric_heroes_profile_img,
      cric_heroes_profile_link : this.state.spin_data[this.state.prizeNo].cric_heroes_profile_link,
      selected_cric_heroes_last_updated_on: this.state.spin_data[this.state.prizeNo].cric_heroes_last_updated_on,
      icon_text  : this.state.spin_data[this.state.prizeNo].icon_text,
      selected_cric_batting_style : this.state.spin_data[this.state.prizeNo].cric_batting_style,
      selected_cric_bowling_style : this.state.spin_data[this.state.prizeNo].cric_bowling_style,
      selected_cric_matches : this.state.spin_data[this.state.prizeNo].cric_matches,
      selected_cric_runs : this.state.spin_data[this.state.prizeNo].cric_runs,
      selected_cric_wickets : this.state.spin_data[this.state.prizeNo].cric_wickets,
      selected_cric_tags : this.state.spin_data[this.state.prizeNo].cric_tags,
    });
    
    this.showSellModal(this.state.spin_data[this.state.prizeNo].option, this.state.spin_data[this.state.prizeNo].player_name, this.state.spin_data[this.state.prizeNo].player_id, this.state.spin_data[this.state.prizeNo].player_pic, this.state.spin_data[this.state.prizeNo].player_cric_link,this.state.spin_data[this.state.prizeNo].icon_text);

    setTimeout(
      () =>     document.getElementById('a_sold_amount').focus()      ,
      700
    );


  };

  showSellModal(random_sl, player_name, player_id, player_pic, player_cric_link,icon_text) {

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = params.get('i');
    //alert(random_sl)
    //    document.getElementById('i_sell_modal_html').innerHTML = "<h3>"+random_sl+"</h3>";


    //getPlayerProfile(group_fk,user_fk,'i_profile_modal',invite_code);
    //call API i_profile_modal
    //alert(group_fk + ' ' + user_fk)
    /*
    this.setState({
      show: !this.state.show
    });
    */
  };

  closeshowSell = e => {
    this.setState({
      showSell: false
    });

  };
    
    hideCricProfile = e => {
    
      document.getElementById("cric_profile_td").style.display="none";
      document.getElementById('a_sold_amount').focus() ;

  };


  closeEditshowSell = e => {
    this.setState({
      showEditSell: false
    });



  };



  componentDidMount() {
    this.callbasicapi();
    
    document.addEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    // Check if Alt + N is pressed
    //alert(event.key)
    if (event.altKey && event.which == 78) {
      // Trigger button click event
      
      document.getElementById('startspinbtn').click();
      //this.setState({ enter_manually: true });

    }
    else     if (event.altKey && event.which == 77) {
      // Trigger button click event
      
      this.setState({ enter_manually: 1 });
      setTimeout(
        () =>     document.getElementById('e_enter_manually').focus()      ,
        700
      );
      //this.setState({ x: true });

    }

    

    else if (event.altKey && event.which == 83) {
      // Trigger button click event
      
      document.getElementById('sellplayerbtn').click();
      //this.setState({ x: true });

    }

    

  };

  auction_sell_enter= (event) => {

    if(event.key == 'Enter'){
      document.getElementById('sellplayerbtn').click();
    }
  }
  
  auction_manual= (event) => {
    if(event.key == 'Enter'){
      //alert('Adding....');
      console.log(this.state.spin_data)
      var e_enter_manually  = document.getElementById('e_enter_manually').value;

      //this.state.prizeNo

      var array_index = this.state.spin_data.findIndex(item => item.option === e_enter_manually);
      
      if(array_index  === -1)
      {
        return;
      }
      
      this.setState({
        prizeNo:array_index
      });

      
      this.setState({
        x: false, prizeNo: 1, showSell: !this.state.showSell,
        prizeNo:array_index,
        selected_sl: this.state.spin_data[array_index].option,
        selected_player: this.state.spin_data[array_index].player_name,
        selected_player_pic : this.state.spin_data[array_index].player_pic,
        selected_cric_img: this.state.spin_data[array_index].cric_heroes_profile_img,
        cric_heroes_profile_link : this.state.spin_data[array_index].cric_heroes_profile_link,
        selected_cric_heroes_last_updated_on: this.state.spin_data[array_index].cric_heroes_last_updated_on,
        icon_text: this.state.spin_data[array_index].icon_text,
        selected_cric_batting_style: this.state.spin_data[array_index].cric_batting_style,
        selected_cric_bowling_style: this.state.spin_data[array_index].cric_bowling_style,
        selected_cric_matches: this.state.spin_data[array_index].cric_matches,
        selected_cric_runs: this.state.spin_data[array_index].cric_runs,
        selected_cric_wickets: this.state.spin_data[array_index].cric_wickets,
        selected_cric_tags: this.state.spin_data[array_index].cric_tags

      });
      this.showSellModal(this.state.spin_data[array_index].option, this.state.spin_data[array_index].player_name, this.state.spin_data[array_index].player_id, this.state.spin_data[array_index].player_pic, this.state.spin_data[array_index].player_cric_link,this.state.spin_data[array_index].icon_text);
      setTimeout(
        () =>     document.getElementById('a_sold_amount').focus()      ,
        700
      );
      
  }

  }

  onClickStartPin= (e) => {
    this.callbasicapi(1);
    //this.setState({ x: true });
  }

  onClickRandomTeam= (e) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = atob(params.get('id'));

    const post_data = { series_id: series_id};
    console.log(post_data)
    //      alert(group_id)
    //,{headers: {"Content-Type": "application/json"}}
    axios.post(global.config.app.backend_api_url + `Setteam/auto`, post_data, { withCredentials: true }
    )
      .then(res => {
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if (res.data.status == '0') {
          alert(res.data.message);
        }
        else {
          alert(res.data.message);
          this.setState({ showSell: false });
          this.callbasicapi();
        }

      });




  }

  getCurrentBid() {
    try{
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = atob(params.get('id'));

    const post_data = { series_id: series_id};
    //document.getElementById("right_div_for_bid").innerHTML = '';
    //console.log(post_data)
    //      alert(group_id)
    //,{headers: {"Content-Type": "application/json"}}
    axios.post(global.config.app.backend_api_url + `auction/get_current_bid`, post_data,
     { withCredentials: true,timeout: 3000 }
    )
      .then(res => {
        //const normalmembers = res.data;  
        try{
        console.log(res.data.current_bid_amount)
        const current_bid_amount = res.data.current_bid_amount;
        if(res.data.current_bid_teams_html!=='')
        {
          document.getElementById('right_team_table').innerHTML = res.data.current_bid_teams_html;
        }

        const rows = document.querySelectorAll("tr");
          rows.forEach((row) => {
            const maxBidCell = row.querySelector(".teamlist_amount");
            if (maxBidCell) {
              const maxBidValue = parseFloat(maxBidCell.textContent);
              if (maxBidValue <= current_bid_amount) {
                row.querySelectorAll(".teamlist_right").forEach((cell) => {
                  cell.classList.add("disabled_team");
                });
              } else {
                row.querySelectorAll(".teamlist_right").forEach((cell) => {
                  cell.classList.remove("disabled_team");
                });
              }
            }
          });
        //document.getElementById("right_div_for_bid").innerHTML = res.data.current_bid_teams_html;
        }catch(Err)
        {
          
        }
      });
    }catch(Errr)
    {

    }
  }


  updateCurrentBid(current_bid_amount) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = atob(params.get('id'));

    var current_bid_player  = this.state.selected_new_player_id;

    const post_data = { series_id: series_id,current_bid_amount:current_bid_amount,current_bid_player:current_bid_player};
    console.log(post_data)
    //      alert(group_id)
    //,{headers: {"Content-Type": "application/json"}}
    axios.post(global.config.app.backend_api_url + `auction/update_current_bid`, post_data, { withCredentials: true ,timeout: 2000}
    )
      .then(res => {
        //const normalmembers = res.data;  
        console.log("current bid updated")
        console.log(res.data)
        

      });




  }

  onClickSell = (e) => {

    var a_match_team_id = document.getElementById('a_match_team_id').value
    var a_sold_amount = document.getElementById('a_sold_amount').value
    document.getElementById("sellplayerbtn").style.display = "none";
    document.getElementById("loadergif").style.display = "";
    

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = atob(params.get('id'));

    const post_data = { series_id: series_id, match_team_fk: a_match_team_id, random_slno: this.state.selected_sl, auction_sold_amount: a_sold_amount };
    console.log(post_data)
    //      alert(group_id)
    //,{headers: {"Content-Type": "application/json"}}
    axios.post(global.config.app.backend_api_url + `auction/sell_a_player`, post_data, { withCredentials: true }
    )
      .then(res => {
        document.getElementById("sellplayerbtn").style.display = "";
        document.getElementById("loadergif").style.display = "none";


        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if (res.data.status == '0') {
          alert(res.data.message);
        }
        else {
          alert(res.data.message);
          this.setState({ showSell: false });
          this.callbasicapi();
        }

      });

    // alert('API to be called');
  }

  onClickEditSell = (e) => {

    var a_match_team_id = document.getElementById('e_a_match_team_id').value
    var a_sold_amount = document.getElementById('e_a_sold_amount').value

    document.getElementById("changeditbtn").style.display="none";
    document.getElementById("loadergif_change").style.display="";
    


    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = atob(params.get('id'));
    //alert(this.state.e_selected_sl)
    const post_data = { series_id: series_id, match_team_fk: a_match_team_id,user_fk:this.state.e_selected_user_fk, random_slno: this.state.e_selected_sl, new_sold_amount: a_sold_amount };
    console.log(post_data)
    //      alert(group_id)
    //,{headers: {"Content-Type": "application/json"}}
    axios.post(global.config.app.backend_api_url + `auction/change_team_of_a_sold_player`, post_data, { withCredentials: true }
    )
      .then(res => {
        document.getElementById("loadergif_change").style.display="none";
        document.getElementById("changeditbtn").style.display="";

        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if (res.data.status == '0') {
          alert(res.data.message);
        }
        else {
          alert(res.data.message);
          this.setState({ showEditSell: false });
          this.callbasicapi();
        }

      });

    // alert('API to be called');
  }
  
  onClickReverseSoldPlayer = (e) => {

    if (window.confirm('Are you sure?') == false) {
      return false;
    }

    document.getElementById("changeswapplayer_rev").style.display="none";
    //document.getElementById("loadergif_change").style.display="";
    


    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = atob(params.get('id'));
    //alert(this.state.e_selected_sl)
    const post_data = { series_id: series_id, user_fk:this.state.e_selected_user_fk};
    console.log(post_data)
    //      alert(group_id)
    //,{headers: {"Content-Type": "application/json"}}
    axios.post(global.config.app.backend_api_url + `auction/undo_sold_player`, post_data, { withCredentials: true }
    )
      .then(res => {
        //document.getElementById("loadergif_change").style.display="none";
        document.getElementById("changeswapplayer_rev").style.display="";

        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if (res.data.status == '0') {
          alert(res.data.message);
        }
        else {
          alert(res.data.message);
          this.setState({ showEditSell: false });
          this.callbasicapi();
        }

      });

    // alert('API to be called');
  }



  onClickSwapPlayer = (e) => {

    var e_new_user_mobile_number = document.getElementById('e_new_user_mobile_number').value

    document.getElementById("changeswapplayer").style.display="none";
    //document.getElementById("loadergif_change").style.display="";
    


    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = atob(params.get('id'));
    //alert(this.state.e_selected_sl)
    const post_data = { series_id: series_id, new_user_mobile_number: e_new_user_mobile_number,old_user_fk:this.state.e_selected_user_fk};
    console.log(post_data)
    //      alert(group_id)
    //,{headers: {"Content-Type": "application/json"}}
    axios.post(global.config.app.backend_api_url + `auction/swap_player_after_sold`, post_data, { withCredentials: true }
    )
      .then(res => {
        //document.getElementById("loadergif_change").style.display="none";
        document.getElementById("changeswapplayer").style.display="";

        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if (res.data.status == '0') {
          alert(res.data.message);
        }
        else {
          alert(res.data.message);
          this.setState({ showEditSell: false });
          this.callbasicapi();
        }

      });

    // alert('API to be called');
  }


  onClickReverseUnSold = (user_fk, e) => {

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = atob(params.get('id'));
    const post_data = { series_id: series_id, user_fk: user_fk };
    console.log(post_data)
    document.getElementById("movependingbtn").style.display="none";
    document.getElementById("loadergif_unsold_pending").style.display="";
    
    //      alert(group_id)
    //,{headers: {"Content-Type": "application/json"}}
    axios.post(global.config.app.backend_api_url + `auction/move_unsold_to_pending`, post_data, { withCredentials: true }
    )
      .then(res => {
        document.getElementById("movependingbtn").style.display="";
        document.getElementById("loadergif_unsold_pending").style.display="none";
    
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if (res.data.status == '0') {
          alert(res.data.message);
        }
        else {
          alert(res.data.message);
          this.setState({ showSell: false });
          this.callbasicapi();
        }

      });

    // alert('API to be called');
  };

  onClickUnSold = (e) => {
    if (window.confirm('Are you sure?') == false) {
      return false;
    }
    var a_match_team_id = document.getElementById('a_match_team_id').value
    var a_sold_amount = document.getElementById('a_sold_amount').value
    document.getElementById("unsoldbtn").style.display="none";
    document.getElementById("loadergif_unsold").style.display="";
    
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = atob(params.get('id'));

    const post_data = { series_id: series_id, match_team_fk: a_match_team_id, random_slno: this.state.selected_sl, auction_sold_amount: a_sold_amount };
    console.log(post_data)
    //      alert(group_id)
    //,{headers: {"Content-Type": "application/json"}}
    axios.post(global.config.app.backend_api_url + `auction/unsold`, post_data, { withCredentials: true }
    )
      .then(res => {
        document.getElementById("unsoldbtn").style.display="";
        document.getElementById("loadergif_unsold").style.display="none";
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if (res.data.status == '0') {
          alert(res.data.message);
        }
        else {
          alert(res.data.message);
          this.setState({ showSell: false });
          this.callbasicapi();
        }

      });

    // alert('API to be called');
  }

  onChangeImgFlag = (e) => {
    
    var auction_send_image_flag = document.getElementById('auction_send_image_flag').value

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = atob(params.get('id'));

    const post_data = { series_id: series_id, auction_send_image_flag: auction_send_image_flag};
    console.log(post_data)
    //      alert(group_id)
    //,{headers: {"Content-Type": "application/json"}}
    axios.post(global.config.app.backend_api_url + `auction/update_image_flag`, post_data, { withCredentials: true }
    )
      .then(res => {
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        

      });

    // alert('API to be called');
  }
  onChangeSellAmt = (e) => {
    
    var add_sell_amt_in_notificatioin = document.getElementById('add_sell_amt_in_notificatioin').value

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = atob(params.get('id'));

    const post_data = { series_id: series_id, add_sell_amt_in_notificatioin: add_sell_amt_in_notificatioin};
    console.log(post_data)
    //      alert(group_id)
    //,{headers: {"Content-Type": "application/json"}}
    axios.post(global.config.app.backend_api_url + `auction/update_add_sell_amt_in_notificatioin`, post_data, { withCredentials: true }
    )
      .then(res => {
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        

      });

    // alert('API to be called');
  }


  callbasicapi(startpin = 0) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let series_id = atob(params.get('id'));


    const post_data = { series_id: series_id };
    const headers = {
      'Content-Type': 'application/json'
    }

    axios.post(global.config.app.backend_api_url + `auction/get_current_auction_status/`, post_data, { headers: headers, withCredentials: true }
    )
      .then(res => {
        //const normalmembers = res.data;  
        console.log("after112d")
        var status = res.data.status;
        var message = res.data.message;
        if (status == 0) {
          console.log(message);
          //return
        }
        var auction_details = res.data.auction_details;
        var main_auction_details = auction_details.main[0];
        console.log(main_auction_details)
        var teams_details = auction_details.teams;
        var sold_players = auction_details.sold;
        var unsold_players = auction_details.unsold;
        var pending_players = auction_details.pending;
        var pending_sl = auction_details.pending_sl;
        var random_sl = auction_details.random;

        let spin_data = [];
        const listitems = pending_sl;
        console.log("slno:" + random_sl.random_number);

        console.log("populating")
        if (typeof (listitems) != "undefined") {

          for (let i = 0; i < listitems.length; i++) {
            spin_data.push({ option: listitems[i].option, player_id: listitems[i].user_id, player_name: listitems[i].full_name, player_pic: listitems[i].profile_pic, player_cric_link: listitems[i].cric_heroes_profile_link,cric_heroes_profile_link:listitems[i].cric_heroes_profile_link, 
              cric_heroes_profile_img: listitems[i].cric_heroes_profile_img, 
              icon_text: listitems[i].icon_text,
              cric_batting_style: listitems[i].cric_batting_style,
              cric_bowling_style: listitems[i].cric_bowling_style,
              cric_matches: listitems[i].cric_matches,
              cric_runs: listitems[i].cric_runs,
              cric_wickets: listitems[i].cric_wickets,
              cric_tags: listitems[i].cric_tags,
              
              cric_heroes_last_updated_on: listitems[i].cric_heroes_last_updated_on, style: { backgroundColor: listitems[i].bg, textColor: "white" } });

          }
        }
        var xvalue = false; //for spinner
        if(startpin==1)
        {
          xvalue    =true;
        }
        console.log(auction_details.amounts)
        //org_items
        this.setState({
          main_auction_details: main_auction_details, teams_details: teams_details, sold_players: sold_players,
          unsold_players: unsold_players, pending_players: pending_players, spin_data: spin_data, prizeNo: Number(random_sl.random_number)
          ,x:xvalue,org_items:auction_details.amounts,items:auction_details.amounts
        });

        

      });

  }


  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {

    const { value, items } = this.state;

    return (

      <DashboardLayout>
        <Helmet>
          <title>Auction | {global.config.app.name}</title>
          <meta
            name="description"
            content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
          />
          <meta
            name="keywords"
            content="Cricket, team, join, competitive, skills, community, sign up, field"
          />
        </Helmet>
       {
         /*
         <DashboardNavbar />
         */
       } 
        <Card>

          <SoftBox my={3}>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
              <Grid item xs={12} md={12}>
                {
                  //  alert(this.state.main_auction_details.pending_count)
                  (() => {

                    if (this.state.spin_data.length > 0 && this.state.main_auction_details.team_max_pocket_size>0) {

                      let search = window.location.search;
                      let params = new URLSearchParams(search);
                      let manual = params.get('manual');
                  
                      return (
                        <SoftBox pt={2} pb={3} px={3}>
                          <SpinnerWrapStyle className="App">
                            <Wheel
                              prizeNumber={Number(this.state.prizeNo)}
                              mustStartSpinning={this.state.x}
                              data={this.state.spin_data}
                              backgroundColors={["#c2db62", "#5fbd5a"]}
                              textColors={["#ffffff"]}
                              onStopSpinning={this.stopSpin}
                              radiusLineWidth={1}
                              spinDuration={0.1}
                              outerBorderWidth={1}
                            />

                            <SoftButton id="startspinbtn" variant="gradient" size="small" color="success" onClick={this.onClickStartPin}>
                              Start Spin
                            </SoftButton>
                            {
                  //  alert(this.state.main_auction_details.pending_count)
                  (() => {

                    if (manual == '1' || this.state.enter_manually == '1') {
                            return (
                            <SoftBox pt={2} pb={3} px={3}>
                            <SoftInput placeholder="Enter manually" name="e_enter_manually" onKeyPress={this.auction_manual} id="e_enter_manually" inputProps={{ maxLength: 3 }} />
                            </SoftBox>
                            )
                    }
                  })()
                }

                          </SpinnerWrapStyle>


                        </SoftBox>

                      )
                    }

                  })()

                }

                  {
                  //  alert(this.state.main_auction_details.pending_count)
                  (() => {

                    if (this.state.main_auction_details.team_max_pocket_size==0) {
                      return (
                        
                            
<SoftBox pt={2} pb={3} px={3}>
                            <SoftButton variant="gradient" size="small" color="success" onClick={this.onClickRandomTeam}>
                              Generate Random Team
                            </SoftButton>

                        </SoftBox>

                      )
                    }

                  })()

                }

              </Grid>

              <Grid item xs={12} md={12}>
                <SoftBox display="flex"   alignItems="center" p={1}>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td colSpan='4' style={{ color: 'red' }}>{this.state.main_auction_details.group_name} - {this.state.main_auction_details.series_name}</td>
                      </tr>
                      <tr>
                        <td>
                          No. of Teams:</td>
                        <td>
                          {this.state.main_auction_details.no_of_teams}
                        </td>
                        <td>
                          Players in auction:</td>
                        <td>
                          {this.state.main_auction_details.count_players_in_auction}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Team wallet size:</td>
                        <td>
                          {this.state.main_auction_details.team_max_pocket_size}
                        </td>
                        <td>
                          Player base price:</td>
                        <td>
                          {this.state.main_auction_details.player_base_price}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Pending players:</td>
                        <td>
                          {this.state.main_auction_details.pending_count}
                        </td>
                        <td>
                          Unsold players:</td>
                        <td>
                          {this.state.main_auction_details.unsold_count}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Sold players:</td>
                        <td>
                          {this.state.main_auction_details.sold_count}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>

                </SoftBox>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center"  p={1} pb={0}>
                  Team stats:
                </SoftBox>
                
                <SoftBox mb={4} xs={4} md={4}  alignItems="center" p={2} >

                  {
                    (() => {
                      const listitems = this.state.teams_details;
                      //console.log(listitems);
                      //console.log("populating")
                      if (typeof (listitems) != "undefined") {
                        //console.log(listitems)
                        const rows = [];
                        for (let i = 0; i < listitems.length; i++) {
                          //team_name,  team_logo, current_wallet_balance, current_players_count, players

                          rows.push(<Team team_max_playes_count={this.state.main_auction_details.team_max_playes_count} pr={3} max_bid_for_a_player={listitems[i].max_bid_for_a_player} current_players_count={listitems[i].current_players_count} current_wallet_balance={listitems[i].current_wallet_balance} team_logo={listitems[i].team_logo} players={listitems[i].players}  team_name={listitems[i].team_name} key={i} />);
                        }
                        return rows;
                      }

                    })()

                  }

                </SoftBox>

              </Grid>
            </Grid>
          </SoftBox>


          <SoftBox display="flex" alignItems="flex-start" p={1}>
            <Box sx={{display: 'flex', flexDirection: 'column', }} p={1}>
              <div>
                  Players list with stats:
                  Pending Players
                </div> 
                <div>
                  {
                    (() => {
                      const listitems = this.state.pending_players;
                      //  console.log(listitems);
                      // console.log("populating")
                      if (typeof (listitems) != "undefined") {
                        //console.log(listitems)
                        const rows = [];
                        for (let i = 0; i < listitems.length; i++) {
                          //team_name,  team_logo, current_wallet_balance, current_players_count, players

                          rows.push(<PendingPlayer user_id={listitems[i].user_id} player_name={listitems[i].full_name} sl_no={listitems[i].sl_no} profile_pic={listitems[i].profile_pic} cric_heroes_profile_link={listitems[i].cric_heroes_profile_link} key={i}  onClickPlayerProfileToSell={this.onClickPlayerProfileToSell} />);
                        }
                        return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
                      }

                    })()

                  }
                </div>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column' }} p={1}>
              <div>
                Unsold Players
              </div>

              <div>
                {
                  (() => {
                    const listitems = this.state.unsold_players;
                    // console.log(listitems);
                    // console.log("populating")
                    if (typeof (listitems) != "undefined") {
                      //console.log(listitems)
                      const rows = [];
                      for (let i = 0; i < listitems.length; i++) {
                        //team_name,  team_logo, current_wallet_balance, current_players_count, players

                        rows.push(<UnsoldPlayer user_id={listitems[i].user_id} player_name={listitems[i].full_name} sl_no={listitems[i].sl_no} profile_pic={listitems[i].profile_pic} cric_heroes_profile_link={listitems[i].cric_heroes_profile_link} key={i} />);
                      }
                      return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
                    }

                  })()

                }
              </div>
            </Box>

            


          </SoftBox>

          <SoftBox mb={2}>

<SoftTypography
          variant="button"
          fontWeight="regular"
          
          sx={{ cursor: "poiner", userSelect: "none" }}
        >
          Send with player image ?:
        </SoftTypography>

      <FormSelect size="sm" className="mb-9" name="auction_send_image_flag" id="auction_send_image_flag" onChange={this.onChangeImgFlag}>
  <option value="1" selected={(this.state.main_auction_details.auction_send_image_flag === "1") ? 'selected' : ''}>Yes</option>
  <option value="0" selected={(this.state.main_auction_details.auction_send_image_flag === "0") ? 'selected' : ''}>No</option>
</FormSelect>
</SoftBox>

<SoftBox mb={2}>

<SoftTypography
          variant="button"
          fontWeight="regular"
          
          sx={{ cursor: "poiner", userSelect: "none" }}
        >
          Add sell amount in notification ?:
        </SoftTypography>

      <FormSelect size="sm" className="mb-9" name="add_sell_amt_in_notificatioin" id="add_sell_amt_in_notificatioin" onChange={this.onChangeSellAmt}>
  <option value="1" selected={(this.state.main_auction_details.add_sell_amt_in_notificatioin === "1") ? 'selected' : ''}>Yes</option>
  <option value="0" selected={(this.state.main_auction_details.add_sell_amt_in_notificatioin === "0") ? 'selected' : ''}>No</option>
</FormSelect>

&nbsp;
{
                (() => {


                  

                    var link_to_create = "/makeauction";
                    var querystring = "?id="+btoa(this.state.main_auction_details.group_id)+"&sid="+btoa(this.state.main_auction_details.series_id);
                   // alert(querystring)

                   return (

                    <Link to={{pathname:link_to_create,search:querystring} }>
                      Back to auction
                      </Link>

                    
                    
                   )
                   

                   
                     

                  })()
        
              }


</SoftBox>


        </Card>
        <Footer />

        <Modal
          isOpen={this.state.showSell}
          onRequestClose={this.closeshowSell}
          style={customStyles}
          shouldCloseOnOverlayClick={false}
          contentLabel="Modal"

        >

          <div id='i_sell_modal_html'>
          <SoftBox mb={12}>

          

                  

          <table cellspacing="0" width="100%" cellpadding="0">
    <tr>
    <td width="30%">
      
    {
              (() => {
                const props = {width: 300, zoomPosition:'original',   zoomWidth: '300', img: this.state.selected_player_pic ? (this.state.selected_player_pic):(team_player_2)};
                return (<>
      <ReactImageZoom {...props} />
                </>)
                      
                    })()
                  }
                  </td>
    <td width="35%" valign="top">
     
    <div class="section stats">
    <div class="stat-item"><h3>
    {
      
              (() => {
                var blank_page= global.config.app.backend_api_url + 'playerview?img='+this.state.selected_player_pic+'&sl='+this.state.selected_sl+'&name='+this.state.selected_player+'&cric='+this.state.selected_cric_img;
                
                return (
    <a target="_blank" href={blank_page} style={{color:'#fc0328'}}>
        <span  className="player-number">
                        {this.state.selected_sl}
                      </span></a>)
                      
                    })()
                  }
                  </h3></div>
    <div class="stat-item playername" style={{backgroundColor:"#424242", color:"#E0F7FA" }} ><h3>{this.state.selected_player}</h3></div>
    
    
    
    {
      (() => {
    if(this.state.icon_text){
      if(this.state.icon_text  !=  '' && this.state.icon_text != null)
      {

      
              
              //link
            return (
              <div class="stat-item icon">{this.state.icon_text}</div>
            )
            
      }
        }
      })()
    }



    {
      (() => {
    if(this.state.selected_cric_batting_style){
      if(this.state.selected_cric_batting_style  !=  '' && this.state.selected_cric_batting_style != null)
      {

      
              
              //link
            return (
              <div class="stat-item batting">{this.state.selected_cric_batting_style}</div>
            )
            
      }
        }
      })()
    }

{
      (() => {
    if(this.state.selected_cric_bowling_style){
      if(this.state.selected_cric_bowling_style  !=  '' && this.state.selected_cric_bowling_style != null)
      {

      
              
              //link
            return (
              <div class="stat-item bowling">{this.state.selected_cric_bowling_style}</div>
            )
            
      }
        }
      })()
    }


{
      (() => {
    if(this.state.selected_cric_matches){
      if(this.state.selected_cric_matches  !=  '' && this.state.selected_cric_matches != null)
      {

      
              
              //link
            return (
              <div class="stat-item matches">Matches: {this.state.selected_cric_matches}</div>
            )
            
      }
        }
      })()
    }

{
      (() => {
    if(this.state.selected_cric_runs){
      if(this.state.selected_cric_runs  !=  '' && this.state.selected_cric_runs != null)
      {

      
              
              //link
            return (
              <div class="stat-item runs">Runs: {this.state.selected_cric_runs}</div>
            )
            
      }
        }
      })()
    }
{
      (() => {
    if(this.state.selected_cric_wickets){
      if(this.state.selected_cric_wickets  !=  '' && this.state.selected_cric_wickets != null)
      {

      
              
              //link
            return (
              <div class="stat-item wickets">Wickets: {this.state.selected_cric_wickets}</div>
            )
            
      }
        }
      })()
    }

                </div>

    </td>
    <td  width="25%" valign="top" id="right_team_table">
      <table style={{fontSize:"16px",border: "1px solid #ddd"}} >
                        <thead>
                            <tr>
                                <th style={{fontSize:"16px",border: "1px solid #ddd"}}>Team Name</th>
                                <th style={{fontSize:"16px",border: "1px solid #ddd"}}>Count</th>
                                <th style={{fontSize:"16px",border: "1px solid #ddd"}}>Max</th>
                            </tr>
                        </thead>
                        <tbody>

                        {
                    (() => {
                      const listitems = this.state.teams_details;
                      //console.log(listitems);
                      //console.log("populating")
                      if (typeof (listitems) != "undefined") {
                        //console.log(listitems)
                        const rows = [];
                        for (let i = 0; i < listitems.length; i++) {
                          //team_name,  team_logo, current_wallet_balance, current_players_count, players
                          if(listitems[i].team_max_playes_count !==  listitems[i].current_players_count)
                          {
                           
                          rows.push(
                          <tr >
                          <td className="teamlist_right" style={{border: "1px solid #ddd"}}>{listitems[i].team_name}</td>
                                <td className="teamlist_right" style={{border: "1px solid #ddd"}}>{listitems[i].current_players_count}/{listitems[i].team_max_playes_count}</td>
                                <td  className="teamlist_right teamlist_amount" style={{border: "1px solid #ddd"}}>{listitems[i].max_bid_for_a_player}</td>
                          </tr>
                          
                          //<PopTeam pr={3} max_bid_for_a_player={listitems[i].max_bid_for_a_player} current_players_count={listitems[i].current_players_count} current_wallet_balance={listitems[i].current_wallet_balance} team_logo={listitems[i].team_logo} players={listitems[i].players}  team_name={listitems[i].team_name} key={i} />
                          );
                          }
                        }
                        return rows;
                      }

                    })()

                  }



{
  /*
                    (() => {
                      const listitems = this.state.teams_details;
                      //console.log(listitems);
                      //console.log("populating")
                      if (typeof (listitems) != "undefined") {
                        //console.log(listitems)
                        const rows = [];
                        for (let i = 0; i < listitems.length; i++) {
                          //team_name,  team_logo, current_wallet_balance, current_players_count, players
                          
                          if(listitems[i].team_max_playes_count ===  listitems[i].current_players_count)
                          {
                            if(i===0)
                            {
                              rows.push(
                                <tr>
                        <td colSpan="3" style={{fontSize:"16px",border: "1px solid #ddd"}}><b>Completed</b></td>
                        </tr>
                                
                                //<PopTeam pr={3} max_bid_for_a_player={listitems[i].max_bid_for_a_player} current_players_count={listitems[i].current_players_count} current_wallet_balance={listitems[i].current_wallet_balance} team_logo={listitems[i].team_logo} players={listitems[i].players}  team_name={listitems[i].team_name} key={i} />
                                );
                            }
                          rows.push(
                          <tr>
                          <td className="complted_team" style={{border: "1px solid #ddd"}}>{listitems[i].team_name}</td>
                                <td className="complted_team" style={{border: "1px solid #ddd"}}>{listitems[i].current_players_count}</td>
                                <td  className="complted_team" style={{border: "1px solid #ddd"}}>{listitems[i].max_bid_for_a_player}</td>
                          </tr>
                          
                          //<PopTeam pr={3} max_bid_for_a_player={listitems[i].max_bid_for_a_player} current_players_count={listitems[i].current_players_count} current_wallet_balance={listitems[i].current_wallet_balance} team_logo={listitems[i].team_logo} players={listitems[i].players}  team_name={listitems[i].team_name} key={i} />
                          );
                          }
                        }
                        return rows;
                      }

                    })()
*/
                  }

                            
                        </tbody>
                    </table></td>

  <td  width="10%" valign="top" style={{marginRight:'5px'}}>

  <img src={livelogo} alt="Image" class="aligned-image" width="130px" height="130px" />
  {
      (() => {
    if(this.state.main_auction_details){
      if(this.state.main_auction_details.league_logo  !=  '' && this.state.main_auction_details.league_logo != null)
      {

      
              
              //link
            return (
            <img src={this.state.main_auction_details.league_logo} alt="Image" class="aligned-image_logo2" width="130px" height="130px"  />
            )
            
      }
        }
      })()
    }


  </td>
    
    </tr>
    </table>
</SoftBox>
            {
              (() => {
                const props = {width: 300, zoomPosition:'original',   zoomWidth: '300', img: this.state.selected_player_pic ? (this.state.selected_player_pic):(team_player_2)};
                var blank_page= global.config.app.backend_api_url + 'playerview?img='+this.state.selected_player_pic+'&sl='+this.state.selected_sl+'&name='+this.state.selected_player+'&cric='+this.state.selected_cric_img;
                
                return (
                  <AuctionModalWrapper>
                    

                    


                    
                    
                    <div  class="aligned-image-right" id="right_div_for_bid">
                    
                    </div>
                    
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          
                          <td style={{ width: '40%' }}>
                    {
                      /*
      <SoftInput placeholder="Sell Amount" autocomplete="off" name="a_sold_amount" id="a_sold_amount" inputProps={{ maxLength: 8 }} />

                      */
                    }
                      
                            <Autocomplete
         name="a_sold_amount" id="a_sold_amount"  inputProps={{ maxLength: 8 }}
        freeSolo
        onChange={this.handleChange}
        onHighlightChange={this.handleHighlightChange}
        options={this.state.items.map((option) => option)}
        renderInput={(params) => <TextField {...params}  label="Sell Amount" />}
      />

                            {
                              /*
                            <AutoComplete dropdownAutoFocus="true" dropdownMode="current" placeholder="Sell Amount" dropdown="true" panelStyle={panelStyle} panelClassName="p-autocomplete-panel" value={this.state.value} suggestions={this.state.items} minLength="0" completeMethod={this.search} className="MuiInputBase-input" inputClassName="col-lg-12" onChange={(e) => this.setState({value:e.value})}  />

                              */
                            }


                          </td>
                          <td style={{ width: '10%' }}>
                            &nbsp;
                          </td>
                          
                          <td style={{ width: '50%' }}>
                            <div className="custom-select-wrap">
                              <FormSelect style={{ width: '100%' }} className="MuiInputBase-root MuiInputBase-colorPrimary auctionteam" name="a_match_team_id" id="a_match_team_id" onKeyPress={this.auction_sell_enter}>
                                <option value="" >Choose a team</option>

                                {
                                  (() => {
                                    const listitems = this.state.teams_details;
                                    //console.log(listitems);
                                    //console.log("populating")
                                    if (typeof (listitems) != "undefined") {
                                      //console.log(listitems)
                                      const rows = [];
                                      for (let i = 0; i < listitems.length; i++) {
                                        //team_name,  team_logo, current_wallet_balance, current_players_count, players

                                        rows.push(<option value={listitems[i].match_team_id}>{listitems[i].team_name} ({listitems[i].current_players_count}) (Rs:{listitems[i].max_bid_for_a_player})</option>);
                                      }
                                      return rows;
                                      //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
                                    }

                                  })()

                                }
                              </FormSelect>
                            </div>
                          </td>
                          
                        </tr>
                        <tr><td colSpan="3"><Box sx={{mt: 1}}/></td></tr>
                        <tr>
                          <td>
                            <SoftButton variant="gradient" id="unsoldbtn" size="small" color="error" onClick={this.onClickUnSold}>
                              Mark as Unsold
                            </SoftButton>
                            <img src={loader_img} id="loadergif_unsold" style={{display:'none'}} class="zoom2" height="30"/>
                          </td>
                          <td></td>
                          <td>
                          <SoftButton variant="gradient" id="sellplayerbtn" size="small" color="success"
                            onClick={this.onClickSell}>
                            Sell Player
                          </SoftButton>
                          <img src={loader_img} id="loadergif" style={{display:'none'}} class="zoom2" height="30"/>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    
                            <hr></hr>    
                            <br></br>  
                    <div style={{maxHeight:'100px',overflow:'true'}}>
                    {
                    (() => {
                      const listitems = this.state.teams_details;
                      //console.log(listitems);
                      //console.log("populating")
                      if (typeof (listitems) != "undefined") {
                        //console.log(listitems)
                        const rows = [];
                        for (let i = 0; i < listitems.length; i++) {
                          //team_name,  team_logo, current_wallet_balance, current_players_count, players

                          rows.push(<PopTeam pr={3} max_bid_for_a_player={listitems[i].max_bid_for_a_player} current_players_count={listitems[i].current_players_count} current_wallet_balance={listitems[i].current_wallet_balance} team_logo={listitems[i].team_logo} players={listitems[i].players}  team_name={listitems[i].team_name} key={i} />);
                        }
                        return rows;
                      }

                    })()

                  }
                    </div>

                  </AuctionModalWrapper>
                )

              })()
            }
          </div>
          <button variant="button" className='close-btn' onClick={this.closeshowSell}>
                    <CloseIcon />
                  </button>

        </Modal>


        <Modal
          isOpen={this.state.showEditSell}
          onRequestClose={this.closeEditshowSell}
          style={customStyles1}
          shouldCloseOnOverlayClick={false}
          contentLabel="Modal"
        >

          <div id='i_sell_modal_html1'>

            {
              (() => {

                return (
                  <div >
                    <h1 color="danger" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red', fontSize: 78 }}>
                      {this.state.e_selected_sl}
                    </h1>
                    <h3 color="danger" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 78 }}>
                      {this.state.e_selected_player}
                    </h3>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '50%' }}>
                            <FormSelect style={{ width: '100%' }} className="MuiInputBase-root MuiInputBase-colorPrimary " name="e_a_match_team_id" id="e_a_match_team_id">
                              <option value="" >Choose a team</option>

                              {
                                (() => {
                                  const listitems = this.state.teams_details;
                                  //console.log(listitems);
                                  //console.log("populating")
                                  if (typeof (listitems) != "undefined") {
                                    //console.log(listitems)
                                    const rows = [];
                                    for (let i = 0; i < listitems.length; i++) {
                                      //team_name,  team_logo, current_wallet_balance, current_players_count, players

                                      rows.push(<option value={listitems[i].match_team_id}>{listitems[i].team_name}</option>);
                                    }
                                    return rows;
                                    //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
                                  }

                                })()

                              }

                            </FormSelect>
                          </td>
                          <td style={{ width: '10%' }}>
                            &nbsp;
                          </td>
                          <td style={{ width: '40%' }}>
                            <SoftInput  placeholder="Sell Amount" name="e_a_sold_amount" id="e_a_sold_amount" inputProps={{ maxLength: 8 }} />
                          </td>
                        </tr>
                        <tr>
                          <td>

                          </td>
                          <td>
                          </td>
                          <SoftButton variant="gradient" id="changeditbtn" size="small" color="success" title="Change it"
                            onClick={this.onClickEditSell}>
                            Change
                          </SoftButton>
                          <img src={loader_img} id="loadergif_change" style={{display:'none'}} class="zoom2" height="30"/>
                          <td>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table style={{ width: '30%' }}>
                      <tbody>
                        <tr>
                          <td>
                    <SoftInput  placeholder="Enter mobile number" name="e_new_user_mobile_number" id="e_new_user_mobile_number" inputProps={{ maxLength: 10 }} />
                    
                    <SoftButton variant="gradient" id="changeswapplayer" size="small" color="success" title="Change Player"
                            onClick={this.onClickSwapPlayer}>
                            Change to a new player
                          </SoftButton>
                          </td>
                          </tr>


                          <tr>
                          <td>
                          <br>
                    </br>
                    <SoftButton variant="gradient" id="changeswapplayer_rev" size="small" color="error" title="Reverse Player"
                            onClick={this.onClickReverseSoldPlayer}>
                            Reverse
                          </SoftButton>
                          </td>
                          </tr>

                          </tbody>
                          </table>


                  </div>
                )

              })()
            }
          </div>
          <button variant="button" fontWeight="medium" color="info" onClick={this.closeEditshowSell}>close</button>
        </Modal>
        
      </DashboardLayout>
    );
  }
}
export default AuctionSpinner;

