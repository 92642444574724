/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import React from 'react';  
import { useLocation, Link } from "react-router-dom";
import FormSelect from 'react-bootstrap/FormSelect';

// @mui material components
import Card from "@mui/material/Card";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Helmet } from "react-helmet";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import {useEffect, useState, useRef} from 'react';
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

// Data
//import groupsTableData from "layouts/groups/data/groupsTableData";

import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import step1 from "assets/images/step1.png";
import step2 from "assets/images/step2.png";
import step3 from "assets/images/step3.png";
import step4 from "assets/images/step4.png";
import gr_logo from "assets/images/group.png";

import { Navigate } from "react-router-dom";
import SoftButton from "components/SoftButton";
import { FaPenSquare } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";

import { FaImage } from "react-icons/fa";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography variant="button" fontWeight="medium" color="info">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};
function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
};

export default class MakeAuction extends React.Component {  
  constructor(props) {
    super(props);
    this.setState({loggedIn: '1'});
    this.setState({showModal: false});
    this.setState({showModalTelegram: false});
    
          let search = window.location.search;
  let params = new URLSearchParams(search);
  
  let series_id ='';
  if(params.get('sid')!=null)
  {
    series_id = atob(params.get('sid'));
  }
  const s_main_auction_details = {
                "no_of_teams": "0",
                "count_players_in_auction": "0",
                "player_base_price": "0",
                "team_max_pocket_size": "0",
                "team_max_playes_count": "0",
                "series_name": "Match",
                "group_name": "Group",
                "series_id": "1",
                "is_auction": "0",
                "auction_started": "0",
                "team_max_players_count": "0",
                "auction_link": "",
                "auction_code": "",
                "pending_count": "0",
                "sold_count": "0",
                "unsold_count": "0",
                "teams_added_count":"0"
            }

    this.state = { teams_added_count:0,loggedIn: '1',showModal: false,viewstatus:'auctioninfo',selected_series_id: series_id,selected_team:'',main_auction_details:s_main_auction_details};

  }
  state = {  
    posts: []  
  } 
  onClickRemoveTeam = (match_team_id,e)=> {
  
  if(window.confirm("Do you want to remove?")==false)
  {
    return false;
  }
  var series_id = this.state.selected_series_id;

  const post_data = { series_id:series_id,match_team_id:match_team_id };

    axios.post( global.config.app.backend_api_url + `auction/remove_team/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });

  };
onStartAuction= (e)=> {
  if(window.confirm("Please ensure that all teams and details are entered correctly. Once the auction has started, you cannot undo this process.")==false)
  {
    return false;
  }
  var series_id = this.state.selected_series_id;

  const post_data = { series_id:series_id };

    axios.post( global.config.app.backend_api_url + `auction/start_auction/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });

};


onResetAuction= (e)=> {
  if(window.confirm("Once the auction has reset, you cannot undo this process.Sold player details will be deleted")==false)
  {
    return false;
  }
  var series_id = this.state.selected_series_id;

  const post_data = { series_id:series_id };

    axios.post( global.config.app.backend_api_url + `auction/reset_auction/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });

};


onGetPlayerslist= (e)=> {
 
  var selected_series_url = this.state.invite_link;

  const post_data = { series_code:selected_series_url };

    axios.post( global.config.app.backend_api_url + `auction/get_players_list/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });

};


onGetPlayerslist2= (e)=> {
 
  var selected_series_url = this.state.invite_link;

  const post_data = { series_code:selected_series_url,photo:1,cric:0 };

    axios.post( global.config.app.backend_api_url + `auction/get_players_list/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });

};

onGetPlayerslist_PDF= (e)=> {
 
  var selected_series_url = this.state.invite_link;

  const post_data = { series_code:selected_series_url,photo:1,cric:0,pdf:1 };

    axios.post( global.config.app.backend_api_url + `auction/get_players_list/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });

};

onGetPlayerslist_PDF_Cric= (e)=> {
 
  var selected_series_url = this.state.invite_link;

  const post_data = { series_code:selected_series_url,photo:1,cric:1 ,pdf:1};

    axios.post( global.config.app.backend_api_url + `auction/get_players_list/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });

};

onChangePDFFormat= (e)=> {
 
  var selected_series_id = this.state.selected_series_id;
  var pdf_format  = document.getElementById('pdf_format').value;
  const post_data = { series_id:selected_series_id,pdf_format:pdf_format};

    axios.post( global.config.app.backend_api_url + `auction/update_pdf_format/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("onChangePDFFormat1")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
      }

    });

};

onChangeIncludeStats= (e)=> {
 
  var selected_series_id = this.state.selected_series_id;
  var include_cric_details  = document.getElementById('include_cric_details').value;
  const post_data = { series_id:selected_series_id,include_cric_details:include_cric_details};

    axios.post( global.config.app.backend_api_url + `auction/update_pdf_include_stats_flag/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("onChangePDFFormat1")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
      }

    });

};


onNotifyAuction = (e)=> {
 
  var selected_series_id = this.state.selected_series_id;

  const post_data = { series_id:selected_series_id};

    axios.post( global.config.app.backend_api_url + `auction/notify_auction/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
      }

    });

};



onGetTeamList= (e)=> {
 
  let search = window.location.search;
                    let params = new URLSearchParams(search);

              let group_id_n =params.get('sid');
//alert(group_id_n)
  var selected_series_url = this.state.main_auction_details.auction_code;// this.state.invite_link;

  const post_data = { series_code:selected_series_url,photo:1 };

    axios.post( global.config.app.backend_api_url + `Share_team/share/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });

};


onGetTeamListGroup= (e)=> {
 
  let search = window.location.search;
                    let params = new URLSearchParams(search);

              let group_id_n =params.get('sid');
//alert(group_id_n)
  var selected_series_url = this.state.main_auction_details.auction_code;// this.state.invite_link;

  const post_data = { series_code:selected_series_url,photo:1 ,to_group:1};

    axios.post( global.config.app.backend_api_url + `Share_team/share/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });

};

onGetTeamWisePDF= (e)=> {
 
  let search = window.location.search;
                    let params = new URLSearchParams(search);

              let group_id_n =params.get('sid');
//alert(group_id_n)
  var selected_series_url = this.state.invite_link;// this.state.invite_link;

  const post_data = { series_code:selected_series_url,photo:1 ,group:0};

    axios.post( global.config.app.backend_api_url + `auction/get_teamwise_players_list/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });

};

onGetTeamWisePDFToGroup= (e)=> {
 
  let search = window.location.search;
                    let params = new URLSearchParams(search);

              let group_id_n =params.get('sid');
//alert(group_id_n)
  var selected_series_url = this.state.invite_link;// this.state.invite_link;

  const post_data = { series_code:selected_series_url,photo:1 ,group:1};

    axios.post( global.config.app.backend_api_url + `auction/get_teamwise_players_list/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });

};


onClickUpdateMakeAuction = (e)=> {

  var no_of_teams = document.getElementById('no_of_teams').value
  var series_id = this.state.selected_series_id;
  var count_players_in_auction = document.getElementById('count_players_in_auction').value
  var player_base_price = document.getElementById('player_base_price').value
  var team_max_pocket_size = document.getElementById('team_max_pocket_size').value
  var team_max_playes_count = document.getElementById('team_max_playes_count').value


  var a_enable_browser_push= document.getElementById('a_enable_browser_push').value;
  var a_enable_email_push= document.getElementById('a_enable_email_push').value;
  var a_enable_telegram_push= document.getElementById('a_enable_telegram_push').value;
  var a_enable_whatsapp_push= document.getElementById('a_enable_whatsapp_push').value;

  var captain_flag= document.getElementById('captain_flag').value;
var add_sell_amt_in_notificatioin= document.getElementById('add_sell_amt_in_notificatioin').value;


  const post_data = { series_id:series_id, no_of_teams: no_of_teams,count_players_in_auction : count_players_in_auction,
  player_base_price:player_base_price,
    team_max_pocket_size:team_max_pocket_size,team_max_playes_count:team_max_playes_count,
    a_enable_browser_push:a_enable_browser_push,a_enable_email_push:a_enable_email_push,
     a_enable_telegram_push:a_enable_telegram_push,a_enable_whatsapp_push:a_enable_whatsapp_push,captain_flag:captain_flag
    ,add_sell_amt_in_notificatioin:add_sell_amt_in_notificatioin};

    axios.post( global.config.app.backend_api_url + `auction/make_auction/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });



// alert('API to be called');
}

onClickUpdateTeam = (e)=> {

  var series_id = this.state.selected_series_id;
  var match_team_id = this.state.selected_team;

  var team_name = document.getElementById('team_name').value
  var captain_user_fk = document.getElementById('captain_user_fk').value
  var description= document.getElementById('description').value
  var captains_price= document.getElementById('captains_price').value


  const post_data = { series_id:series_id, match_team_id: match_team_id,team_name : team_name,
  captain_user_fk:captain_user_fk,description:description,captains_price:captains_price};

    axios.post( global.config.app.backend_api_url + `auction/add_edit_team/`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'auctioninfo'});
        
      }

    });



// alert('API to be called');
}

callbasicapi()
{
  
  let search = window.location.search;
  let params = new URLSearchParams(search);
  
  const post_data = { series_id:this.state.selected_series_id};
  //alert(this.state.selected_series_id)
  axios.post(global.config.app.backend_api_url +`auction/get_auction_info`,post_data, { withCredentials: true })  
  .then(res => {  
    //const posts = res.data;  
    
    var auction_details  = res.data.auction_details;
    var main_auction_details  = [];
    try{
    main_auction_details  = auction_details.main[0];
    }catch(Err)
    {

    }
    var teams_added_count = auction_details.teams_added_count;

    var teams_details  = [];
    try{
    teams_details  = auction_details.teams;
    }catch(Err)
    {

    }
    var all_payers  = [];
    try{
    all_payers  = auction_details.all_payers;
    }catch(Err)
    {

    }
    
    const posts1 = this.createTeamRows(teams_details);
    console.log("getting auction response23 ");
    //console.log(res.data)
   // alert(res.data.match_date)
    this.setState({a_series_name:res.data.series_name,a_match_date:res.data.match_date,a_from_time:res.data.from_time,
      invite_link : res.data.invite_link,
       teams_details_table:posts1,main_auction_details:main_auction_details,teams_details:teams_details,all_payers:all_payers,series_notification_type: res.data.series_notification_type,series_captain_flag: res.data.series_captain_flag,
       series_captain_flag2: res.data.series_captain_flag2,
       teams_added_count:teams_added_count });  
    //alert(res.data.user_details.user_id)
    try{
    if(res.data.user_details.user_id!='')
    {
      this.callNotificationSubscribe(res.data.user_details.nai,res.data.user_details.user_id);
    }
    }catch(Err)
    {

    }

  });
}


onImageSeriesLogoUpd = event => {

  let search = window.location.search;
                    let params = new URLSearchParams(search);

              let group_id_n = params.get('sid');
//alert(group_id_n)
  if (event.target.files && event.target.files[0]) {
    let img = event.target.files[0];
    //alert(this.state.selected_group)

    const formData = new FormData();
    formData.append("series_logo_img", event.target.files[0]);
    formData.append("series_id", this.state.selected_series_id);
    //call API

    axios.post( global.config.app.backend_api_url + `auction/upload_series_logo`,formData, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if(res.data.status=='0')
        {
          alert(res.data.message);
        }
        else
        {
          alert(res.data.message);
          //alert(res.data.profile_pic)
          /*
          this.setState({
            image: URL.createObjectURL(img)
          });
          */
          this.callbasicapi();
          this.setState({ selected_group : '', viewstatus: 'auctioninfo'});

        }
  
      });

      

   
  }
};


onImageSeriesLogoHeaderUpd = event => {

  let search = window.location.search;
                    let params = new URLSearchParams(search);

              let group_id_n = params.get('sid');
//alert(group_id_n)
  if (event.target.files && event.target.files[0]) {
    let img = event.target.files[0];
    //alert(this.state.selected_group)

    const formData = new FormData();
    formData.append("series_logo_img", event.target.files[0]);
    formData.append("series_id", this.state.selected_series_id);
    //call API

    axios.post( global.config.app.backend_api_url + `auction/upload_series_logo_header`,formData, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if(res.data.status=='0')
        {
          alert(res.data.message);
        }
        else
        {
          alert(res.data.message);
          //alert(res.data.profile_pic)
          /*
          this.setState({
            image: URL.createObjectURL(img)
          });
          */
          this.callbasicapi();
          this.setState({ selected_group : '', viewstatus: 'auctioninfo'});

        }
  
      });

      

   
  }
};

onImageUploadPDFBanner = event => {

  let search = window.location.search;
                    let params = new URLSearchParams(search);

              let group_id_n = params.get('sid');
//alert(group_id_n)
  if (event.target.files && event.target.files[0]) {
    let img = event.target.files[0];
    //alert(this.state.selected_group)

    const formData = new FormData();
    formData.append("series_pdf_banner", event.target.files[0]);
    formData.append("series_id", this.state.selected_series_id);
    //call API

    axios.post( global.config.app.backend_api_url + `auction/upload_series_pdf_banner`,formData, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if(res.data.status=='0')
        {
          alert(res.data.message);
        }
        else
        {
          alert(res.data.message);
          //alert(res.data.profile_pic)
          /*
          this.setState({
            image: URL.createObjectURL(img)
          });
          */
          this.callbasicapi();
          this.setState({ selected_group : '', viewstatus: 'auctioninfo'});

        }
  
      });

      

   
  }
};

onImageUploadPDFBackground = event => {

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let group_id_n = params.get('sid');
//alert(group_id_n)
  if (event.target.files && event.target.files[0]) {
    let img = event.target.files[0];
    //alert(this.state.selected_group)

    const formData = new FormData();
    formData.append("series_pdf_bg", event.target.files[0]);
    formData.append("series_id", this.state.selected_series_id);
    //call API

    axios.post( global.config.app.backend_api_url + `auction/upload_series_pdf_background`,formData, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if(res.data.status=='0')
        {
          alert(res.data.message);
        }
        else
        {
          alert(res.data.message);
          //alert(res.data.profile_pic)
          /*
          this.setState({
            image: URL.createObjectURL(img)
          });
          */
          this.callbasicapi();
          this.setState({ selected_group : '', viewstatus: 'auctioninfo'});

        }
  
      });

      

   
  }
};

onImageChange = event => {
  if (event.target.files && event.target.files[0]) {
    let img = event.target.files[0];
    //alert(this.state.selected_group)

    const formData = new FormData();
    formData.append("group_logo_img", event.target.files[0]);
    formData.append("team_id", this.state.selected_team);
    //call API

    axios.post( global.config.app.backend_api_url + `auction/upload_team_logo`,formData, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if(res.data.status=='0')
        {
          alert(res.data.message);
        }
        else
        {
          alert(res.data.message);
          //alert(res.data.profile_pic)
          /*
          this.setState({
            image: URL.createObjectURL(img)
          });
          */
          this.callbasicapi();
          this.setState({ selected_group : '', viewstatus: 'auctioninfo'});

        }
  
      });

      

   
  }
};
closeModalTelegram = e => {
  this.setState({
    showModalTelegram: false
  });

};
generateWhatsappcode(type,e)
{
  var typename='Main Group';
  if(type=='WG')
  {
    typename='Guest Group';
  }
  const post_data = { ref_type:type,group_id:this.state.selected_group};

    axios.post( global.config.app.backend_api_url + `connect/generatewhatsappcode`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        //alert(res.data.message);
      }
      else
      {
        //alert(res.data.wa_main_chat_id)
        document.getElementById('wa_main_chat_id').value = res.data.wa_main_chat_id;
        document.getElementById('wa_guest_chat_id').value = res.data.wa_guest_chat_id;
        
        document.getElementById('whatsapp_div').style.display='';
        
          //this.setState({telegramsecret: res.data.code,telegramtypename:typename});
        
      }

    });
}
generateTelegramcode(type,e)
{
  var typename='Main Group';
  if(type=='GG')
  {
    typename='Guest Group';
  }
  const post_data = { ref_type:type,group_id:this.state.selected_group};

    axios.post( global.config.app.backend_api_url + `connect/generatetelegramcode`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        //alert(res.data.message);
      }
      else
      {
        document.getElementById('telegram_div').style.display='';
        
          this.setState({telegramsecret: res.data.code,telegramtypename:typename});
        
      }

    });

  //call API
  //this.setState({showModalTelegram: !this.state.showModalTelegram }); //!this.state.showModalTelegram
}


setauctionteam= e => {

  //alert(this.state.main_auction_details.player_base_price)
  document.getElementById('team_name').value = this.state.selected_team_name;
  document.getElementById('captain_user_fk').value = this.state.selected_captain_user_fk;
  document.getElementById('description').value = this.state.selected_description;
  document.getElementById('captains_price').value = this.state.selected_captains_price;
  


};

setauctionform= e => {

  //alert(this.state.main_auction_details.player_base_price)
  document.getElementById('no_of_teams').value = this.state.main_auction_details.no_of_teams;
  document.getElementById('count_players_in_auction').value = this.state.main_auction_details.count_players_in_auction;
  document.getElementById('player_base_price').value = this.state.main_auction_details.player_base_price;
  document.getElementById('team_max_pocket_size').value = this.state.main_auction_details.team_max_pocket_size;
  document.getElementById('team_max_playes_count').value = this.state.main_auction_details.team_max_playes_count;

  document.getElementById("a_enable_whatsapp_push").value = this.state.main_auction_details.a_enable_whatsapp_push;
  document.getElementById("a_enable_telegram_push").value = this.state.main_auction_details.a_enable_telegram_push;
  document.getElementById("a_enable_email_push").value = this.state.main_auction_details.a_enable_email_push;
  document.getElementById("a_enable_browser_push").value = this.state.main_auction_details.a_enable_browser_push;



};
  componentDidMount() {  


    const post_data = { lo : 1};

    axios.post( global.config.app.backend_api_url + `login/sessionavailable`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        console.log(res.data)
        var user_id = res.data.user_details.user_id;
        
        if (user_id !== undefined && user_id !== null && user_id !== 'null') 
        {
        }else
        {
          window.location.replace('sign-in');
          //return;
        }
  
      });

      this.callbasicapi();

      /*
      axios.get(`http://localhost:9000/testAPIp`, { withCredentials: true })  
      .then(res => {  
        this.setState({loggedIn: res.data.loggedIn, designation: "your value"});

      });*/

  }  

  callNotificationSubscribe(appid,userid)
  {
    if(userid=='' || appid=='')
    {
      return;
    }
    const s = document.createElement("script")
    s.src = "https://notix.io/ent/current/enot.min.js"
    s.onload = (sdk) => {
        sdk.startInstall({
            appId: appid,
            loadSettings: true,
            disableConsoleDebug: true,
            user: userid
        });

    }
    document.head.append(s);
  }
    
  deleteRow(id, e){  
    console.log("deleted id : "+ id);
    console.log( this.state.posts);
    const posts = this.state.posts.filter(item => item.id !== id);  
    this.setState({ posts }); 
    /*
    axios.delete(`http://localhost:9000/groups/${id}`)  
      .then(res => {  
        console.log(res);  
        console.log(res.data);  
    
        const posts = this.state.posts.filter(item => item.id !== id);  
        this.setState({ posts });  
      })  */
    
  }  

  
  
  

   groupsTableData = {
    columns: [
      { name: "team_logo", align: "left" },
      { name: "team_name", align: "left" },
      { name: "captain", align: "left" },
      { name: "captains_price", align: "left" },
      { name: "max_wallet_balance", align: "left" },
      { name: "current_wallet_balance", align: "left" },
      { name: "max_bid_for_a_player", align: "left" },
      { name: "action", align: "left" },
    ],
  
    rows: [
      {
        groupname: <Author image={team2} name="Friends Group"  />,
        location: <Function job="Trivandrum"  />,
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        createdon: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            12/11/2022
          </SoftTypography>
        ),
        action: (
          <SoftBox>
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            Edit
          </SoftTypography>

          <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
          >
          View
          </SoftTypography>
          </SoftBox>

        ),
      }
      
    ],
  };

  createTeamRows(res)
{
  //loop here and create rows in soft UI rows
  console.log(res)
  const plainObj = [];

  var iloop ;
  for(iloop=0; iloop < res.length; iloop++){
      const thisid = res[iloop].match_team_id;
      const s_team_name = res[iloop].team_name;
      const s_captain_user_fk = res[iloop].captain_user_fk;
      const s_description= res[iloop].description;
      const s_captains_price= res[iloop].captains_price;
      const max_bid_for_a_player  =  res[iloop].max_bid_for_a_player;
      const current_wallet_balance  =  res[iloop].current_wallet_balance;
      const href_link = "/viewmembers?id="+btoa(thisid);
      const is_admin= res[iloop].is_admin;
      var slno = iloop + 1;
      //loopData += `<li>${data[i].name}</li>`
      var arrayLike = {};
      
         arrayLike = {
          team_logo: (
          <SoftBox>{slno}
          <SoftAvatar src={res[iloop].team_logo ? (res[iloop].team_logo):(gr_logo)} alt={res[iloop].team_name} size="sm" variant="rounded" />
          </SoftBox>    
  ),
          team_name: <Function job={res[iloop].team_name}  />,
          captain: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {res[iloop].captain}
            </SoftTypography>
          ),
          'captains_price': res[iloop].captains_price,
          'max_wallet_balance': res[iloop].max_wallet_balance,
          'current_wallet_balance': res[iloop].current_wallet_balance,
          'max_bid_for_a_player': res[iloop].max_bid_for_a_player,
          'action' : (
            <SoftBox>{/*
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              onClick={() => this.deleteRow(thisid)}
            >
              View&nbsp;&nbsp;
            </SoftTypography>
           */}
            <SoftTypography
              component="a"
              href="javascript:void(0)"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              onClick={() => this.setState({viewstatus: 'editteam',selected_team :thisid,selected_team_name: s_team_name,selected_captain_user_fk: s_captain_user_fk,selected_description:s_description,selected_captains_price: s_captains_price})}
            >
              <FaPenSquare></FaPenSquare> 
            </SoftTypography>
            &nbsp;&nbsp;&nbsp;

            <SoftTypography
              component="a"
              href="javascript:void(0)"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              onClick={() => this.onClickRemoveTeam(thisid)}
            >
              <FaMinusCircle></FaMinusCircle> 
            </SoftTypography>


            

            &nbsp;&nbsp;&nbsp;
<SoftTypography
              component="a"
              href="javascript:void(0)"
              variant="caption"
              color="secondary"
              title="Upload logo"
              fontWeight="medium"
              onClick={() => this.setState({viewstatus: 'uploadteamlogo',selected_team:thisid})}
            >
              <FaImage></FaImage> 
            </SoftTypography>

            

            </SoftBox>
            
          ),
          'id' : res[iloop].id
        };
      

      Array.prototype.push.call(plainObj, arrayLike);

  }
  
    //const myObjStr = JSON.stringify(plainObj);
    console.log("obj:")
    console.log(plainObj)
    console.log("res:")
    console.log(res)
    
    return plainObj;
  }

  
  


  render() {  

    const handleClose = () => {
      this.setState({showModal: false});
    }
    const handleShow = () => {
      this.setState({showModal: true});
    }

    if(this.state.loggedIn!=='1')
    {
     // return <Navigate to="/sign-in" />
  
    };

  const { columns, rows } = this.groupsTableData;
  //const [list, setList] = useState([]);
  //const [actuallist, setActuallist] = useState([]);

    
    return (  
      <DashboardLayout>
      <Helmet>
      <title>Make Auction | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
        {
                (() => {
                  if(this.state.viewstatus === 'auctioninfo' ){ 
                   return (
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Auction Details [{this.state.a_series_name} - {this.state.a_match_date}]</SoftTypography>
              {
                (() => {
                 // if(this.state.main_auction_details){
                    
              if(this.state.teams_added_count==0)
                      {
                        
                        return(
              <SoftButton variant="gradient" size="small" color="info"  onClick={() => this.setState({viewstatus: 'makeauction'})}>
                
                {
                (() => {
                  if(this.state.main_auction_details){
                    if(this.state.main_auction_details.is_auction=='1')
                    {
                      
                        return (<span>Edit Auction</span>)
                      
                    }else
                    {
                      return (<span>Make Auction</span>)
                    }
                  }else
                  {
                    return (<span>Make Auction</span>)
                  }
                })()
                
                }
                
                
              </SoftButton>
                        )
              }
           // }
                      })()
                
                    }

              {
                (() => {
                  if(this.state.main_auction_details){
                    if(this.state.main_auction_details.is_auction=='1')
                    {
                    return (<SoftButton variant="gradient" size="small" color="success"  onClick={() => this.setState({viewstatus: 'editteam',selected_team:''})}>Add a Team</SoftButton>)
                    }
                  }
                })()
                
                }

              
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >

            <table>
    <tbody>
    <tr>
      <td width="15%">
      Name:</td>
    <td  width="45%" style={{color:'red'}}>
    {
    (typeof this.state.main_auction_details !=='undefined' && typeof this.state.main_auction_details.group_name !=='undefined')?
    this.state.main_auction_details.group_name:''
    
    } - 
    {
    (typeof this.state.main_auction_details !=='undefined' && typeof this.state.main_auction_details.series_name !=='undefined')?
    this.state.main_auction_details.series_name:''
    
    }

    </td>
    <td  width="15%">
    No. of Teams:</td>
    <td  width="25%">
        {
    (typeof this.state.main_auction_details !=='undefined' && typeof this.state.main_auction_details.no_of_teams !=='undefined')?
    this.state.main_auction_details.no_of_teams:''
    
    }
     </td>
    </tr>

    <tr>
    <td>
    Players in auction:</td>
    <td>
             {
    (typeof this.state.main_auction_details !=='undefined' && typeof this.state.main_auction_details.count_players_in_auction !=='undefined')?
    this.state.main_auction_details.count_players_in_auction:''
    
    }

     </td>
    <td>
    Team wallet size:</td>
    <td>
            {
    (typeof this.state.main_auction_details !=='undefined' && typeof this.state.main_auction_details.team_max_pocket_size !=='undefined')?
    this.state.main_auction_details.team_max_pocket_size:''
    
    }     </td>
    </tr>

    <tr>
    <td>
    Player base price:</td>
    <td>
    {
    (typeof this.state.main_auction_details !=='undefined' && typeof this.state.main_auction_details.player_base_price !=='undefined')?
    this.state.main_auction_details.player_base_price:''
    
    }
     </td>
    <td>
    Pending players:</td>
    <td>
    {
    (typeof this.state.main_auction_details !=='undefined' && typeof this.state.main_auction_details.pending_count !=='undefined')?
    this.state.main_auction_details.pending_count:''
    
    }
     </td>
    </tr>

    <tr>
    <td>
    Unsold players:</td>
    <td>
    {
    (typeof this.state.main_auction_details !=='undefined' && typeof this.state.main_auction_details.unsold_count !=='undefined')?
    this.state.main_auction_details.unsold_count:''
    
    }
     </td>
    <td>
    Sold players:</td>
    <td>
        {
    (typeof this.state.main_auction_details !=='undefined' && typeof this.state.main_auction_details.sold_count !=='undefined')?
    this.state.main_auction_details.sold_count:''
    
    }

     </td>
    </tr>
    </tbody>
    </table>

    {
      (() => {
    if(this.state.main_auction_details){
          if(this.state.main_auction_details.is_auction=='1' && this.state.main_auction_details.auction_started == '0')
            {
              //link
            return (<SoftButton variant="gradient" size="small" color="error"  onClick={this.onStartAuction}>Start Auction</SoftButton>)
            }
            else if(this.state.main_auction_details.auction_started=='1')
            {
                        let search = window.location.search;
                    let params = new URLSearchParams(search);

              let group_id_n = '/auction?id='+params.get('sid');

              return (<Link to={group_id_n}>
                
                  <SoftButton variant="gradient" size="small" color="success" >
                    Goto Auction
                  </SoftButton>
                
              </Link>)
            }

            

            
        }
      })()
    }
    &nbsp;
    {
(() => {
  if(this.state.main_auction_details){


if(this.state.main_auction_details.auction_started!='0')
{
         

  let group_id_n = this.state.main_auction_details.auction_link;

  return (<Link to={group_id_n}>
    
      <SoftButton variant="gradient" size="small" color="success" >
        Get URL to Publish
      </SoftButton>
    
  </Link>)
}
  }

    
  })()
}

              <Table columns={columns} rows={this.state.teams_details_table} />

              {
      (() => {
    if(this.state.main_auction_details){
          if(this.state.main_auction_details.is_auction=='1' && this.state.main_auction_details.auction_started == '1' )
            {
              //link
            return (<SoftButton variant="gradient" size="small" color="error"  onClick={this.onResetAuction}>Reset Auction</SoftButton>)
            }
            
        }
      })()
    }

    <SoftButton variant="gradient" size="small" color="info"  onClick={this.onGetPlayerslist}>Get Players List</SoftButton>
    <SoftButton variant="gradient" size="small" color="info"  onClick={this.onGetPlayerslist2}>Get Players List with Photos</SoftButton>

    <SoftButton variant="gradient" size="small" color="info"  onClick={this.onGetPlayerslist_PDF}>Get Players List PDF</SoftButton>
    <SoftButton variant="gradient" size="small" color="info"  onClick={this.onGetPlayerslist_PDF_Cric}>Get Players List PDF(Cric)</SoftButton>
    

    <SoftButton variant="gradient" size="small" color="success"  onClick={() => this.setState({viewstatus: 'uploadserieslogo'})}>Upload Logo</SoftButton>
    <SoftButton variant="gradient" size="small" color="success"  onClick={() => this.setState({viewstatus: 'uploadserieslogoheader'})}>Upload Poster</SoftButton>
    <SoftButton variant="gradient" size="small" color="success"  onClick={() => this.setState({viewstatus: 'uploadpdfbanner'})}>Upload PDF Banner</SoftButton>

    <SoftButton variant="gradient" size="small" color="success"  onClick={() => this.setState({viewstatus: 'uploadpdfbg'})}>Upload PDF Background</SoftButton>

{
      (() => {
        const replace_url =  global.config.app.backend_api_url + `share_player_cards/replacements?series_code=` + this.state.invite_link;

   return ( <Link to={replace_url} target="_blank">
    <SoftButton variant="gradient" size="small" color="error"  >Replacements</SoftButton>
  </Link>)
   })()
    }


    {
      (() => {
    if(this.state.main_auction_details){
          if(this.state.main_auction_details.pending_count=='0')
            {

              
              //link
            return (<SoftButton variant="gradient" size="small" color="info"  onClick={this.onGetTeamList}>Get Team List</SoftButton>)
            }
            
        }
      })()
    }

{
      (() => {
    if(this.state.main_auction_details){
          if(this.state.main_auction_details.pending_count=='0')
            {

              
              //link
            return (<SoftButton variant="gradient" size="small" color="info"  onClick={this.onGetTeamListGroup}>Get Team List To Group</SoftButton>)
            }
            
        }
      })()
    }


{
      (() => {
    
        var link_to_create = "/mi";
                    var querystring = "?i="+this.state.invite_link;

              
              //link
            return (<Link to={{pathname:link_to_create,search:querystring} }>
              <SoftButton variant="gradient" size="small" color="info" >Participants List</SoftButton>
              </Link>)
            
            
        
      })()
    }


{
      (() => {
    if(this.state.main_auction_details){
          if(this.state.main_auction_details.pending_count=='0')
            {

              
              //link
            return (<SoftButton variant="gradient" size="small" color="info"  onClick={this.onGetTeamWisePDF}>Get Team Wise PDF</SoftButton>)
            }
            
        }
      })()
    }
{
      (() => {
    if(this.state.main_auction_details){
          if(this.state.main_auction_details.pending_count=='0')
            {

              
              //link
            return (<SoftButton variant="gradient" size="small" color="info"  onClick={this.onGetTeamWisePDFToGroup}>Get Team Wise PDF To Group</SoftButton>)
            }
            
        }
      })()
    }



<SoftButton variant="gradient" size="small" color="error"  onClick={this.onNotifyAuction}>Notify Auction</SoftButton>


</SoftBox>

<SoftBox p={3} mb={1} textAlign="center">
  PDF Type

  <FormSelect size="sm" className="mb-9" name="pdf_format" id="pdf_format" onChange={this.onChangePDFFormat} >
  <option 
      value="1" 
      selected={(this.state.main_auction_details?.pdf_format || "1") === "1" ? 'selected' : ''}
    >
      Default
    </option>
    <option 
      value="2" 
      selected={(this.state.main_auction_details?.pdf_format || "") === "2" ? 'selected' : ''}
    >
      Format 2
    </option>
    
    </FormSelect>

</SoftBox>

<SoftBox p={3} mb={1} textAlign="center">
  Show Stats

  <FormSelect size="sm" className="mb-9" name="include_cric_details" id="include_cric_details" onChange={this.onChangeIncludeStats} >
  <option 
      value="1" 
      selected={(this.state.main_auction_details?.include_cric_details || "1") === "1" ? 'selected' : ''}
    >
      Yes
    </option>
    <option 
      value="0" 
      selected={(this.state.main_auction_details?.include_cric_details || "") === "0" ? 'selected' : ''}
    >
      No
    </option>
    
    </FormSelect>

</SoftBox>



            {
      (() => {
    if(this.state.main_auction_details){
      if(this.state.main_auction_details.league_logo  !=  '')
      {

      
              
              //link
            return (<SoftBox><br/><hr/><br/> Logo : 
              <img style={{maxWidth:'400px'}} src={this.state.main_auction_details.league_logo} />
              </SoftBox>)
            
      }
        }
      })()
    }
{
      (() => {
    if(this.state.main_auction_details){
      if(this.state.main_auction_details.league_logo_header_img  !=  '')
      {

      
              
              //link
            return (<SoftBox> Poster : 
              <img style={{maxWidth:'500px'}} src={this.state.main_auction_details.league_logo_header_img} />
              </SoftBox>)
            
      }
        }
      })()
    }


{
      (() => {
    if(this.state.main_auction_details){
      if(this.state.main_auction_details.league_pdf_banner  !=  '')
      {

      
              
              //link
            return (<SoftBox> PDF Banner : 
              <img style={{maxWidth:'100%'}} src={this.state.main_auction_details.league_pdf_banner} />
              </SoftBox>)
            
      }
        }
      })()
    }


{
      (() => {
    if(this.state.main_auction_details){
      if(this.state.main_auction_details.league_pdf_background  !=  '')
      {

      
              
              //link
            return (<SoftBox> PDF Background : 
              <img style={{maxWidth:'100%'}} src={this.state.main_auction_details.league_pdf_background} />
              </SoftBox>)
            
      }
        }
      })()
    }

            
          </Card>
        )}
        
        else if(this.state.viewstatus === 'uploadserieslogo' )
        { 
          return (
            <Card>

<SoftBox p={3} mb={1} textAlign="center">
<SoftTypography variant="h5" fontWeight="medium">
  Upload Series/Tournament Logo
</SoftTypography>
</SoftBox>

<SoftBox pt={2} pb={3} px={3}>
<SoftBox component="form" role="form" id="groupform">
  
<SoftBox mb={2}>
   <SoftInput type="file" name="s_tournament_logo_img" id="s_tournament_logo_img" placeholder="Logo pic" onChange={this.onImageSeriesLogoUpd}  />

 
  </SoftBox>
  
</SoftBox>

<Link to="/groups">
      
        <SoftTypography
          variant="button"
          fontWeight="medium"
          color="dark"
          onClick={() => this.setState({viewstatus: 'list',selected_group:''})}
        >
          Back 
        </SoftTypography>
      
    </Link>
   

</SoftBox>

</Card>
          )
        }

        else if(this.state.viewstatus === 'uploadserieslogoheader' )
        { 
          return (
            <Card>

<SoftBox p={3} mb={1} textAlign="center">
<SoftTypography variant="h5" fontWeight="medium">
  Upload Series/Tournament Main Poster
</SoftTypography>
</SoftBox>

<SoftBox pt={2} pb={3} px={3}>
<SoftBox component="form" role="form" id="groupform">
  
<SoftBox mb={2}>
   <SoftInput type="file" name="s_tournament_logo_img" id="s_tournament_logo_img" placeholder="Poster pic" onChange={this.onImageSeriesLogoHeaderUpd}  />

 
  </SoftBox>
  
</SoftBox>

<Link to="/groups">
      
        <SoftTypography
          variant="button"
          fontWeight="medium"
          color="dark"
          onClick={() => this.setState({viewstatus: 'list',selected_group:''})}
        >
          Back 
        </SoftTypography>
      
    </Link>
   

</SoftBox>

</Card>
          )
        }
        else if(this.state.viewstatus === 'uploadpdfbanner' )
        { 
          return (
            <Card>

<SoftBox p={3} mb={1} textAlign="center">
<SoftTypography variant="h5" fontWeight="medium">
  Upload PDF Banner
</SoftTypography>
</SoftBox>

<SoftBox pt={2} pb={3} px={3}>
<SoftBox component="form" role="form" id="groupform">
  
<SoftBox mb={2}>
   <SoftInput type="file" name="league_pdf_banner" id="league_pdf_banner" placeholder="PDF Banner pic" onChange={this.onImageUploadPDFBanner}  />

 
  </SoftBox>
  
</SoftBox>

<Link to="/groups">
      
        <SoftTypography
          variant="button"
          fontWeight="medium"
          color="dark"
          onClick={() => this.setState({viewstatus: 'list',selected_group:''})}
        >
          Back 
        </SoftTypography>
      
    </Link>
   

</SoftBox>

</Card>
          )
        }
        else if(this.state.viewstatus === 'uploadpdfbg' )
        { 
          return (
            <Card>

<SoftBox p={3} mb={1} textAlign="center">
<SoftTypography variant="h5" fontWeight="medium">
  Upload PDF Background
</SoftTypography>
</SoftBox>

<SoftBox pt={2} pb={3} px={3}>
<SoftBox component="form" role="form" id="groupform">
  
<SoftBox mb={2}>
   <SoftInput type="file" name="league_pdf_background" id="league_pdf_background" placeholder="PDF Background" onChange={this.onImageUploadPDFBackground}  />

 
  </SoftBox>
  
</SoftBox>

<Link to="/groups">
      
        <SoftTypography
          variant="button"
          fontWeight="medium"
          color="dark"
          onClick={() => this.setState({viewstatus: 'list',selected_group:''})}
        >
          Back 
        </SoftTypography>
      
    </Link>
   

</SoftBox>

</Card>
          )
        }
        else if(this.state.viewstatus === 'uploadteamlogo' )
        { 
          return (
            <Card>

<SoftBox p={3} mb={1} textAlign="center">
<SoftTypography variant="h5" fontWeight="medium">
  Upload Team Logo
</SoftTypography>
</SoftBox>

<SoftBox pt={2} pb={3} px={3}>
<SoftBox component="form" role="form" id="groupform">
  
<SoftBox mb={2}>
   <SoftInput type="file" name="g_group_logo_img" id="g_group_logo_img" placeholder="Profile pic" onChange={this.onImageChange}  />

 
  </SoftBox>
  
</SoftBox>

<Link to="/groups">
      
        <SoftTypography
          variant="button"
          fontWeight="medium"
          color="dark"
          onClick={() => this.setState({viewstatus: 'list',selected_group:''})}
        >
          Back 
        </SoftTypography>
      
    </Link>
   

</SoftBox>

</Card>
          )
        }
        else if(this.state.viewstatus === 'editteam' )
        {
//          alert(this.state.main_auction_details.teams_added_count)

          if(this.state.teams_added_count==0)
          {
            alert('Once a team is added, acution details cannot be edited.')

          }

          let group_caption='Add a Team';
          let group_save_btn = 'Add';
          console.log("acution info")
         // console.log(this.state.main_auction_details.is_auction)
          var is_auction  = '';
          try{
            is_auction  = this.state.main_auction_details.is_auction;
          }catch(err)
          {

          }

          if(this.state.selected_team !=='') //
          {
            group_caption = 'Edit Team';
            group_save_btn  ='Update';
            if(this.state.main_auction_details)
            {
              //this.ref_player_base_price.current= this.state.main_auction_details.player_base_price;
              setTimeout(this.setauctionteam, 200);

            }
          }
      
      


         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {group_caption}
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <SoftInput placeholder="Team Name" name="team_name" id="team_name" inputProps={{ maxLength: 80}}  />
            </SoftBox>
            <SoftBox mb={2}>
              
              
              <FormSelect style={{width:'100%'}} className="MuiInputBase-root MuiInputBase-colorPrimary " name="captain_user_fk" id="captain_user_fk">
                        <option value="" >Choose a captain</option>
                        
                        {
                  (() => {
                  const listitems = this.state.all_payers;
                  //console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {
                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  
                  rows.push(<option value={listitems[i].user_id}>{listitems[i].full_name}</option>);
              }
              return rows;
              //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }

                        </FormSelect>

            </SoftBox>

            <SoftBox mb={2}>
              <SoftInput placeholder="Captain's price"  name="captains_price" id="captains_price" inputProps={{ maxLength: 8}}  />
            </SoftBox>


            <SoftBox mb={2}>
              <SoftInput placeholder="Description with cricheroes team join link" name="description" id="description" inputProps={{ maxLength: 380}}  />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={this.onClickUpdateTeam}>
                {group_save_btn}
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    style={{cursor:'pointer'}}
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'auctioninfo'})}
                  >
                    View auction details
                  </SoftTypography>
        </SoftBox>

          </Card>
        
        )
        

        }
        else if(this.state.viewstatus === 'makeauction' )
        { 


          let group_caption='Make Auction';
          let group_save_btn = 'Create';
          console.log("acution info")
         // console.log(this.state.main_auction_details.is_auction)
          var is_auction  = '';
          try{
            is_auction  = this.state.main_auction_details.is_auction;
          }catch(err)
          {

          }

          this.ref_player_base_price = React.createRef();

          if(is_auction =='1') //
          {
            group_caption = 'Edit Auction';
            group_save_btn  ='Update';
            if(this.state.main_auction_details)
            {
              //this.ref_player_base_price.current= this.state.main_auction_details.player_base_price;
              setTimeout(this.setauctionform, 200);

            }
          }
      
      


         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {group_caption} [{this.state.a_series_name} - {this.state.a_match_date}]
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <SoftInput placeholder="No. of teams" name="no_of_teams" id="no_of_teams" inputProps={{ maxLength: 2}}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput placeholder="Count of players in auction" name="count_players_in_auction"  id="count_players_in_auction" inputProps={{ maxLength: 4}}  />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftInput placeholder="A player's base price" name="player_base_price" id="player_base_price" inputProps={{ maxLength: 7}}  />
            </SoftBox>

             <SoftBox mb={2}>
              <SoftInput placeholder="A team's maximum pocket size"  name="team_max_pocket_size" id="team_max_pocket_size" inputProps={{ maxLength: 8}}  />
            </SoftBox>

             <SoftBox mb={2}>
              <SoftInput placeholder="A team's maximum players" name="team_max_playes_count"  id="team_max_playes_count" inputProps={{ maxLength: 4}}  />
            </SoftBox>

            <SoftBox mb={2}>

      <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                Whatsapp Group Notification on joining and removing of participants ?:
              </SoftTypography>

            <FormSelect size="sm" className="mb-9" name="a_enable_whatsapp_push" id="a_enable_whatsapp_push">
          {
                  (() => {
                  const listitems = this.state.series_notification_type;
                  //console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {

                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  if(listitems[i].show_for_group_notification=='1'){
                  rows.push(<option value={listitems[i].notification_type_id}>{listitems[i].notification_type_name}</option>);
                  }
              }
              return rows;
              //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }

      </FormSelect>
      </SoftBox>

<SoftBox mb={2}>

      <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                Telegram Group Notification on joining and removing of participants?:
              </SoftTypography>

            <FormSelect size="sm" className="mb-9" name="a_enable_telegram_push" id="a_enable_telegram_push">
          {
                  (() => {
                  const listitems = this.state.series_notification_type;
                  //console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {

                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  if(listitems[i].show_for_group_notification=='1'){
                  rows.push(<option value={listitems[i].notification_type_id}>{listitems[i].notification_type_name}</option>);
                  }
              }
              return rows;
              //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }

      </FormSelect>
      </SoftBox>


<SoftBox mb={2}>

      <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                Individual Email Notification on joining and removing of participants?:
              </SoftTypography>

            <FormSelect size="sm" className="mb-9" name="a_enable_email_push" id="a_enable_email_push">
          {
                  (() => {
                  const listitems = this.state.series_notification_type;
                  //console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {

                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  rows.push(<option value={listitems[i].notification_type_id}>{listitems[i].notification_type_name}</option>);
                  
              }
              return rows;
              //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }

      </FormSelect>
      </SoftBox>

<SoftBox mb={2}>

      <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                Individual web browser push notification on joining and removing of participants?:
              </SoftTypography>

            <FormSelect size="sm" className="mb-9" name="a_enable_browser_push" id="a_enable_browser_push">
          {
                  (() => {
                  const listitems = this.state.series_notification_type;
                  //console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {

                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  rows.push(<option value={listitems[i].notification_type_id}>{listitems[i].notification_type_name}</option>);
                  
              }
              return rows;
              //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }

      </FormSelect>
      </SoftBox>



      <SoftBox mb={2}>

<SoftTypography
          variant="button"
          fontWeight="regular"
          
          sx={{ cursor: "poiner", userSelect: "none" }}
        >
          Add captain to the team by default?:
        </SoftTypography>

      <FormSelect size="sm" className="mb-9" name="captain_flag" id="captain_flag">
    {
            (() => {
            const listitems = this.state.series_captain_flag2;
            //console.log(listitems);
            //console.log("populating")
            if (typeof(listitems) != "undefined")
            {
            //console.log(listitems)
        const rows = [];
        for (let i = 0; i < listitems.length; i++) 
        {

          //team_name,  team_logo, current_wallet_balance, current_players_count, players
            rows.push(<option value={listitems[i].flag_id}>{listitems[i].flag_name}</option>);
            
        }
        return rows;
        //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
      }

      })()
  
    }

</FormSelect>
</SoftBox>

<SoftBox mb={2}>

<SoftTypography
          variant="button"
          fontWeight="regular"
          
          sx={{ cursor: "poiner", userSelect: "none" }}
        >
          Add sell amount in notification?:
        </SoftTypography>

      <FormSelect size="sm" className="mb-9" name="add_sell_amt_in_notificatioin" id="add_sell_amt_in_notificatioin">
    {
            (() => {
            const listitems = this.state.series_captain_flag;
            //console.log(listitems);
            //console.log("populating")
            if (typeof(listitems) != "undefined")
            {
            //console.log(listitems)
        const rows = [];
        for (let i = 0; i < listitems.length; i++) 
        {

          //team_name,  team_logo, current_wallet_balance, current_players_count, players
            rows.push(<option value={listitems[i].flag_id}>{listitems[i].flag_name}</option>);
            
        }
        return rows;
        //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
      }

      })()
  
    }

</FormSelect>
</SoftBox>


            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={this.onClickUpdateMakeAuction}>
                {group_save_btn}
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    style={{cursor:'pointer'}}
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'auctioninfo'})}
                  >
                    View auction details
                  </SoftTypography>
        </SoftBox>

          </Card>
        
        )
        }

        
        
      })()
        
        }
        </SoftBox>
        
      </SoftBox>
      
     

      <Footer />
      
    </DashboardLayout>
    )  
  }  
}  

