/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import {  Link } from "react-router-dom";


import { Helmet } from "react-helmet";

// Soft UI Dashboard React examples
//import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardLayout from "examples/LayoutContainers/FullDashboardLayout";


import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftInput from "components/SoftInput";
import Box from '@mui/material/Box';

// @mui material components
import Card from "@mui/material/Card";

import Grid from "@mui/material/Grid";

import Parser from 'html-react-parser';
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import team2 from "assets/images/team-2.jpg";

//import Modal from 'react-bootstrap/Modal';

// Data
//import membersTableData from "layouts/groups/data/membersTableData";

import SoftAvatar from "components/SoftAvatar";
import AdComponent from "layouts/liveauction/common/AdComponent";
// Images
import 'auction.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles


import React, { useState, useEffect } from "react";
import FormSelect from 'react-bootstrap/FormSelect';
import { FaBackward } from "react-icons/fa";
import { FaPenSquare } from "react-icons/fa";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width:'60%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {zIndex: 100000}   
};



const data = [
    { option: "0", style: { backgroundColor: "green", textColor: "white" } },
    { option: "1", style: { backgroundColor: "red", textColor: "white" } },
    { option: "3", style: { backgroundColor: "blue", textColor: "white" } },
    { option: "4", style: { backgroundColor: "pink", textColor: "white" } },
    { option: "5", style: { backgroundColor: "orrange", textColor: "white" } },
    { option: "6", style: { backgroundColor: "gray", textColor: "white" } },
    { option: "7", style: { backgroundColor: "black", textColor: "white" } },
    { option: "8", style: { backgroundColor: "red", textColor: "white" } }
    
  ];

  

  function UnsoldPlayer({ user_id, player_name, sl_no, profile_pic, cric_heroes_profile_link, noGutter }) {
    return (
      <SoftBox
        mb={noGutter ? 0 : 1}
      >
            <SoftTypography variant="caption" color="text">
              <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="capitalize">
              {sl_no}. {player_name} 
              </SoftTypography>
              &nbsp;
              
            </SoftTypography>
       
      </SoftBox>
    );
  }
function Team({ sl_no,team_name, team_logo, current_wallet_balance, current_players_count, players, max_bid_for_a_player, noGutter, linktoteam, max_wallet_size,max_players_count }) {

  var linktoteam1 = '/teamplayers';
  var qrystring = '?t=' + linktoteam;

  return (
    <Grid xs={12} md={12} style={{ border: '1px solid rgba(0, 0, 0, 0.05)' ,
    backgroundColor: sl_no % 2 === 0 ? '#f0f0f0' : '#fffffe', // Light hash color
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s',
    ':hover': {
      backgroundColor: '#b2ebf2', // Darker hash color on hover
      transform: 'scale(1.05)',
    },
    
    }}>

      <SoftBox display="flex" p={1}>
        <SoftAvatar
          src={team_logo ? (team_logo) : (team2)}
          alt="profile-image"
          variant="rounded"
          size="sm"
          shadow="sm"
        />
        &nbsp;
        <Link to={{ pathname: linktoteam1, search: qrystring }}>
          {team_name}
        </Link>
      </SoftBox>

      <SoftBox mb={3}>


      <table style={{ width: "100%", fontSize: "14px" }} border="0" cellPadding="3" cellSpacing="3">
                  <tbody>
                    <tr>
                      <td style={{  padding: "4px",   paddingBottom : "5px" , wordBreak : "break-all" }}>Current Wallet Balance</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>:</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>{current_wallet_balance}</td>
                      <td style={{  padding: "4px",  paddingBottom : "5px"  , wordBreak : "break-all" }}>Maximum Wallet </td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>:</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>{max_wallet_size}</td>
                      
                    </tr>

                    <tr>
                      <td style={{  padding: "4px",   paddingBottom : "5px" , wordBreak : "break-all"  }}>Maximum bid for a player</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>:</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" , color : "red" }}>{max_bid_for_a_player}</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" , wordBreak : "break-all"  }}>Current Count</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>:</td>
                      <td style={{  padding: "4px",   paddingBottom : "5px" }}>{current_players_count}/{max_players_count}</td>
                      
                    </tr>

                  </tbody>
        </table>
        </SoftBox>
 
    
      <SoftTypography>
        {
          (() => {
            const listitems = players;
            if (typeof (listitems) != "undefined") {
              return (
                <table style={{ width: "100%", borderCollapse: "collapse", fontSize: "14px" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid black", padding: "4px",width:"5%" }}>#</th>
                      <th style={{ border: "1px solid black", padding: "4px",width:"45%" }}>Name</th>
                      <th style={{ border: "1px solid black", padding: "4px",width:"15%"}}>Auction Sold Amount</th>
                      <th style={{ border: "1px solid black", padding: "4px",width:"5%" }}>PDF Sl No</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {listitems.map((player, index) => (
                      <tr key={index}>
                        <td style={{ border: "1px solid black", padding: "4px" }}>{index + 1}</td>
                        
          

                        <td style={{ border: "1px solid black", padding: "4px" }}><img
            src={player.profile_pic}
            alt={player.full_name}
            className="me-3"
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />{player.full_name} {player.captain_flag == '1' ? '(C)' : ''} {player.last_sold_status_flag == 1 ? <span style={{ color: 'red' }}>***</span> : ''}</td>
                        <td style={{ border: "1px solid black", padding: "4px" }}>{player.auction_sold_amount}</td>
                        <td style={{ border: "1px solid black", padding: "4px" }}>{player.sl_no}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              );
            }
          })()
        }
      </SoftTypography>
    </Grid>
  );
}



class LiveAuction extends React.Component {
  constructor(props) {
    super(props);
        this.state = { x: false,sx: false,main_auction_details:{group_name:'Group Name'},spin_data:data,counter:0
      ,lastClickTime: null,
      timeAgo: ""};
    window.helloComponent = this;

    this.callbasicapi = this.callbasicapi.bind(this);
//    this.setState({x: false,sx: false});

  }

 onEditSellModal  = (sl_no,player_name,team_id,sold_amount,user_id,e)=> 
 {
    this.setState({showEditSell: !this.state.showEditSell,
    e_selected_sl:sl_no,
    e_selected_player:player_name,
    e_team_id:team_id,
    e_sold_amount:sold_amount});

    setTimeout(
        () =>     document.getElementById('e_a_match_team_id').value = team_id, 
        700
    );

   
 };

  //const [x, sx] = React.useState(false);



  componentDidMount() {

    this.callbasicapi();
    
    /*
    this.interval = setInterval(() => {
      this.callbasicapi();
    }, 30000); // 30 seconds in milliseconds


    this.timeout = setTimeout(() => {
      clearInterval(this.interval);
      //this.reconfirmUser();

      toast.error('Please refresh the page', {
        position: "top-right",
        autoClose: false, // Toast disappears in 1 second
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });

    }, 1200000); // 20 minutes in milliseconds  1200000
    */


    this.interval = setInterval(() => {
      const { lastClickTime } = this.state;
      if (lastClickTime) {
        const secondsAgo = Math.floor((new Date() - lastClickTime) / 1000);

        let timeAgo;
        if (secondsAgo < 60) {
          timeAgo = `${secondsAgo} second${secondsAgo !== 1 ? "s" : ""} ago`;
        } else if (secondsAgo < 3600) {
          const minutesAgo = Math.floor(secondsAgo / 60);
          timeAgo = `${minutesAgo} minute${minutesAgo !== 1 ? "s" : ""} ago`;
        } else {
          const hoursAgo = Math.floor(secondsAgo / 3600);
          timeAgo = `${hoursAgo} hour${hoursAgo !== 1 ? "s" : ""} ago`;
        }

        this.setState({ timeAgo });
      }
    }, 1000);


  }
  componentWillUnmount() {
    clearInterval(this.interval);
    //clearInterval(this.interval);
  }

  reconfirmUser = () => {
    // Ask user if they want to stay on the page
    const stayOnPage = window.confirm("Do you want to stay on this page?");
    if (stayOnPage) {
      // Restart the 20 minutes interval again if they want to stay
      this.interval = setInterval(() => {
        this.callbasicapi();
      }, 30000); // Restart 30 second API calls
      this.timeout = setTimeout(() => {
        clearInterval(this.interval);

        //this.reconfirmUser();
      }, 1200000); // Restart the 20 minute timeout
    } else {
      // Handle navigation or action when user does not want to stay
      console.log('User chose to leave');
    }
  }



  callbasicapi()
  {
    let search = window.location.search;
      let params = new URLSearchParams(search);
      let invite_code = params.get('i');

    
      const post_data = { invite_code: invite_code };
      const headers = {
      'Content-Type': 'application/json'
      }
      
    axios.post( global.config.app.backend_api_url + `auction/get_current_auction_status_by_invite_code/`,post_data, {headers: headers, withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
       // console.log("after112d")
        var status  = res.data.status;
        var message  = res.data.message;
        if(status==0)
        {
          //console.log(message);
          //return
        }
        var auction_details  = res.data.auction_details;
        var main_auction_details  = auction_details.main[0];
       // console.log(main_auction_details)
        var teams_details  = auction_details.teams;
//        var sold_players  = auction_details.sold;
        var pending_players  = auction_details.pending;
        
        var top_sell  = auction_details.top_sell;
        var recent_sell = auction_details.recent_sell;
//      
        
        this.setState( {counter : this.state.counter + 1, main_auction_details: main_auction_details,teams_details:teams_details,
        pending_players:pending_players,top_sell:top_sell,recent_sell:recent_sell,lastClickTime: new Date()});

      });
      const customId = "545";

      toast.success('Refreshed!', {
        position: "top-right",
        autoClose: 1000, // Toast disappears in 1 second
        hideProgressBar: true,
        closeOnClick: true,
        toastId: customId,
        pauseOnHover: false,
        draggable: false,
      });
     // console.log("Refreshed")

   
  }
    

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { timeAgo, lastClickTime } = this.state;
    return (

      <DashboardLayout>
      <Helmet>
      <title>Auction | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>
      <Card>
      <div className="refresh-icon">
        <button
          className="btn btn-outline-secondary rounded-circle shadow"
          onClick={this.callbasicapi}
        >
          🔄
        </button>
        {lastClickTime && (
          <p className="mt-2 text-muted">{timeAgo}</p>
        )}
      </div>
      <SoftBox my={3}>
          <Grid container spacing={3}>
            
<Grid item xs={12} md={12}>
   <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
    <table width="100%">
    <tbody>
    <tr>
    <td colSpan='4' style={{color:'red'}}>{this.state.main_auction_details.group_name} - {this.state.main_auction_details.series_name}</td>
    </tr>
    <tr>
                        <td>
                          No. of Teams:</td>
                        <td>
                          {this.state.main_auction_details.no_of_teams}
                        </td>
                        <td>
                          Players in auction:</td>
                        <td>
                          {this.state.main_auction_details.count_players_in_auction}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Team wallet size:</td>
                        <td>
                          {this.state.main_auction_details.team_max_pocket_size}
                        </td>
                        <td>
                          Player base price:</td>
                        <td>
                          {this.state.main_auction_details.player_base_price}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Pending players:</td>
                        <td>
                          {this.state.main_auction_details.pending_count}
                        </td>
                        <td>
                          Unsold players:</td>
                        <td>
                          {this.state.main_auction_details.unsold_count}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Sold players:</td>
                        <td>
                          {this.state.main_auction_details.sold_count}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
    
     
    </tbody>
    </table>

    </SoftBox>

    <SoftBox display="flex" justifyContent="space-between" pb={0} alignItems="center" p={1}>

    <div className="top-bids mt-3 p-2 bg-gradient  rounded shadow-sm">
    <div className="marquee topsell">
  {this.state.top_sell && this.state.top_sell.length > 0 ? (
    (() => {
      const elements = [];
      elements.push(
        <span key='-1' className="me-4">
          <strong>Top 5 Bids : </strong>
        </span>
      );
      for (let i = 0; i < this.state.top_sell.length; i++) {
        const bid = this.state.top_sell[i];
        elements.push(
          <span key={i} className="me-4">
            <strong>{bid.full_name}</strong> sold to <strong>{bid.team_name}</strong> for{" "}
            <strong>{bid.auction_sold_amount}</strong>
          </span>
        );
      }
      return elements;
    })()
  ) : (
    <span></span>
  )}
</div>

<div className="marquee recent">
  {this.state.recent_sell && this.state.recent_sell.length > 0 ? (
    (() => {
      const elements = [];
      elements.push(
        <span key='-1' className="me-4">
          <strong>Recent Bids : </strong>
        </span>
      );
      for (let i = 0; i < this.state.recent_sell.length; i++) {
        const bid = this.state.recent_sell[i];
        elements.push(
          <span key={i} className="me-4">
            <strong>{bid.full_name}</strong> sold to <strong>{bid.team_name}</strong> for{" "}
            <strong>{bid.auction_sold_amount}</strong>
          </span>
        );
      }
      return elements;
    })()
  ) : (
    <span></span>
  )}
</div>


      </div>
</SoftBox>

<SoftBox display="flex" justifyContent="space-between" pb={0} alignItems="center" p={1}>
Teams:
</SoftBox>


<Grid  justifyContent="space-between"  alignItems="center" p={1} >
    
{
                  (() => {
                  const listitems = this.state.teams_details;
                 // console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {
                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  
                  rows.push(
            <Team sl_no={i} max_players_count={this.state.main_auction_details.team_max_playes_count} max_wallet_size={this.state.main_auction_details.team_max_pocket_size} linktoteam={listitems[i].team_hash_code} players={listitems[i].players} max_bid_for_a_player={listitems[i].max_bid_for_a_player} current_players_count={listitems[i].current_players_count} description={listitems[i].description} current_wallet_balance={listitems[i].current_wallet_balance} team_logo={listitems[i].team_logo} team_name={listitems[i].team_name} key={i} />
            );
              }
              return rows;
            }

            })()
        
          }

    </Grid>

    </Grid>
          </Grid>
        </SoftBox>


        <Grid item xs={12} md={12}>
         {/* Pending Players Section */}
         <div className="pending-players mt-4">
  <h3>Pending Players</h3>
  <div className="col-md-12">
    {Array.isArray(this.state.pending_players) &&
      this.state.pending_players.map((player, index) => (
        <div
          key={index}
          className="col-md-12 mb-2 d-flex align-items-center bg-light rounded shadow-sm p-2"
        >
          <img
            src={player.profile_pic}
            alt={player.full_name}
            className="me-3"
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
          <div className="d-flex justify-content-between w-100">
            <h5 className="mb-0" style={{ flex: "2 0 30%" }}>
              {player.full_name}
            </h5>
            <p className="mb-0 text-center" style={{ flex: "1 0 20%" }}>
              {player.sl_no}
            </p>
            <p className="mb-0 text-end" style={{ flex: "1 0 20%" }}>
              {player.auction_sold_status === 2 ? "Unsold" : ""}
            </p>
          </div>
        </div>
      ))}
  </div>
</div>

</Grid>

        

    </Card>
    <ToastContainer />
    <Footer />
    
    </DashboardLayout>
    );
  }
}
export default LiveAuction;