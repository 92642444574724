/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import React from 'react';  
import { useLocation, Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Helmet } from "react-helmet";
import FormSelect from 'react-bootstrap/FormSelect';

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import {useEffect, useState, useRef} from 'react';
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

// Data
//import groupsTableData from "layouts/groups/data/groupsTableData";

import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import step1 from "assets/images/step1.png";
import step2 from "assets/images/step2.png";
import step3 from "assets/images/step3.png";
import step4 from "assets/images/step4.png";
import gr_logo from "assets/images/group.png";

import { Navigate } from "react-router-dom";
import SoftButton from "components/SoftButton";
import { FaPenSquare } from "react-icons/fa";

import { FaImage } from "react-icons/fa";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography variant="button" fontWeight="medium" color="info">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};
function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
};

export default class Groups extends React.Component {  
  constructor(props) {
    super(props);
    this.setState({loggedIn: '1'});
    this.setState({showModal: false});
    this.setState({showModalTelegram: false});
    
    this.setState({viewstatus: 'list'});

    this.state = { loggedIn: '1',showModal: false,viewstatus:'list'};

  }
  state = {  
    posts: []  
  }  

onClickGroupWAUpdate = (e)=> {
  var group_id = this.state.selected_group;

  var wa_main_chat_id = document.getElementById('wa_main_chat_id').value
  var wa_guest_chat_id = document.getElementById('wa_guest_chat_id').value
  var wa_check_members_group= document.getElementById('wa_check_members_group').value


  const post_data = { group_id:group_id, wa_guest_chat_id: wa_guest_chat_id,wa_main_chat_id : wa_main_chat_id 
  ,wa_check_members_group:wa_check_members_group};

    axios.post( global.config.app.backend_api_url + `groups/update_wa_group`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
          alert(res.data.message);
        
        //  this.callbasicapi();
          this.setState({viewstatus: 'list'});
        
      }

    });

 
}
onClickGroupSave = (e)=> {

  var group_name = document.getElementById('group_name').value
  var group_id = this.state.selected_group;

  var location = document.getElementById('location').value
  var main_telegram_chat_id = document.getElementById('main_telegram_chat_id').value
  var guest_telegram_chat_id = document.getElementById('guest_telegram_chat_id').value


  const post_data = { group_id:group_id, group_name: group_name,location : location,main_telegram_chat_id:main_telegram_chat_id,
    guest_telegram_chat_id:guest_telegram_chat_id };

    axios.post( global.config.app.backend_api_url + `groups/add_edit_group`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        
        
          this.callbasicapi();
          this.setState({viewstatus: 'list'});
        
      }

    });



// alert('API to be called');
}
callbasicapi()
{

      
  axios.get(global.config.app.backend_api_url +`groups`, { withCredentials: true })  
  .then(res => {  
    //const posts = res.data;  
    const posts = this.createRows(res.data.group_data);
    console.log("getting group response ");
    console.log(res.data)
    this.setState({ posts });  
    //alert(res.data.user_details.user_id)
    if(res.data.user_details.user_id!='')
    {
      this.callNotificationSubscribe(res.data.user_details.nai,res.data.user_details.user_id);
    }
  });
}

onImageChange = event => {
  if (event.target.files && event.target.files[0]) {
    let img = event.target.files[0];
    //alert(this.state.selected_group)

    const formData = new FormData();
    formData.append("group_logo_img", event.target.files[0]);
    formData.append("group_id", this.state.selected_group);
    //call API

    axios.post( global.config.app.backend_api_url + `groups/uploadgrouplogo`,formData, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if(res.data.status=='0')
        {
          alert(res.data.message);
        }
        else
        {
          alert(res.data.message);
          //alert(res.data.profile_pic)
          /*
          this.setState({
            image: URL.createObjectURL(img)
          });
          */
          this.callbasicapi();
          this.setState({ selected_group : '', viewstatus: 'list'});

        }
  
      });

      

   
  }
};
closeModalTelegram = e => {
  this.setState({
    showModalTelegram: false
  });

};
generateWhatsappcode(type,e)
{
  var typename='Main Group';
  if(type=='WG')
  {
    typename='Guest Group';
  }
  const post_data = { ref_type:type,group_id:this.state.selected_group};

    axios.post( global.config.app.backend_api_url + `connect/generatewhatsappcode`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        //alert(res.data.message);
      }
      else
      {
        
        this.setState({available_whatsapp_groups: res.data.available_whatsapp_groups,sel_wa_main_chat_id:res.data.wa_main_chat_id,
        sel_wa_guest_chat_id:res.data.wa_guest_chat_id
      });

        //alert(res.data.wa_main_chat_id)

        setTimeout(function() {
          document.getElementById('wa_main_chat_id').value = res.data.wa_main_chat_id;
          document.getElementById('wa_guest_chat_id').value = res.data.wa_guest_chat_id;
          document.getElementById('wa_check_members_group').value = res.data.wa_check_members_group;
          

        
        }, 1500);


        //wa_check_members_group
        
        document.getElementById('whatsapp_div').style.display='';
        
          //this.setState({telegramsecret: res.data.code,telegramtypename:typename});
        
      }

    });
}
generateTelegramcode(type,e)
{
  var typename='Main Group';
  if(type=='GG')
  {
    typename='Guest Group';
  }
  const post_data = { ref_type:type,group_id:this.state.selected_group};

    axios.post( global.config.app.backend_api_url + `connect/generatetelegramcode`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        //alert(res.data.message);
      }
      else
      {
        document.getElementById('telegram_div_demo').style.display='';
        
          this.setState({telegramsecret: res.data.code,telegramtypename:typename});
        
      }

    });

  //call API
  //this.setState({showModalTelegram: !this.state.showModalTelegram }); //!this.state.showModalTelegram
}

  componentDidMount() {  

    const post_data = { lo : 1};

    axios.post( global.config.app.backend_api_url + `login/sessionavailable`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        console.log(res.data)
        var user_id = res.data.user_details.user_id;
        
        if (user_id !== undefined && user_id !== null && user_id !== 'null') 
        {
        }else
        {
          window.location.replace('sign-in');
          //return;
        }
  
      });

      this.callbasicapi();

      /*
      axios.get(`http://localhost:9000/testAPIp`, { withCredentials: true })  
      .then(res => {  
        this.setState({loggedIn: res.data.loggedIn, designation: "your value"});

      });*/

  }  

  callNotificationSubscribe(appid,userid)
  {
    if(userid=='' || appid=='')
    {
      return;
    }
    const s = document.createElement("script")
    s.src = "https://notix.io/ent/current/enot.min.js"
    s.onload = (sdk) => {
        sdk.startInstall({
            appId: appid,
            loadSettings: true,
            disableConsoleDebug: true,
            user: userid
        });

    }
    document.head.append(s);
  }
    
  deleteRow(id, e){  
    console.log("deleted id : "+ id);
    console.log( this.state.posts);
    const posts = this.state.posts.filter(item => item.id !== id);  
    this.setState({ posts }); 
    /*
    axios.delete(`http://localhost:9000/groups/${id}`)  
      .then(res => {  
        console.log(res);  
        console.log(res.data);  
    
        const posts = this.state.posts.filter(item => item.id !== id);  
        this.setState({ posts });  
      })  */
    
  }  

  
  
  

   groupsTableData = {
    columns: [
      { name: "groupname", align: "left" },
      { name: "location", align: "left" },
      { name: "memberscount", align: "left" },
      { name: "createdon", align: "left" },
      { name: "action", align: "left" },
    ],
  
    rows: [
      {
        groupname: <Author image={team2} name="Friends Group"  />,
        location: <Function job="Trivandrum"  />,
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        createdon: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            12/11/2022
          </SoftTypography>
        ),
        action: (
          <SoftBox>
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            Edit
          </SoftTypography>

          <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
          >
          View
          </SoftTypography>
          </SoftBox>

        ),
      }
      
    ],
  };

  createRows(res)
{
  //loop here and create rows in soft UI rows
  console.log(res)
  const plainObj = [];

  var iloop ;
  for(iloop=0; iloop < res.length; iloop++){
      const thisid = res[iloop].id;
      const href_link = "/viewmembers?id="+btoa(thisid);
      const is_admin= res[iloop].is_admin;
      //loopData += `<li>${data[i].name}</li>`
      var arrayLike = {};
      if(res[iloop].is_admin  =='1')
      {
         arrayLike = {
          groupname: (
          <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={res[iloop].group_logo ? (res[iloop].group_logo):(gr_logo)} alt={res[iloop].groupname} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" >
          <SoftTypography component="a" href={href_link}  variant="button" fontWeight="medium" color="info">
          {res[iloop].groupname}
          </SoftTypography>
          
        </SoftBox>
      </SoftBox>
  ),
          location: <Function job={res[iloop].location}  />,
          memberscount: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {res[iloop].members_count}
            </SoftTypography>
          ),
          'createdon': res[iloop].createdon,
          'action' : (
            <SoftBox>{/*
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              onClick={() => this.deleteRow(thisid)}
            >
              View&nbsp;&nbsp;
            </SoftTypography>
           */}
            <SoftTypography
              component="a"
              href="javascript:void(0)"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              onClick={() => this.setState({viewstatus: 'create',selected_group:thisid})}
            >
              <FaPenSquare></FaPenSquare> 
            </SoftTypography>
            &nbsp;&nbsp;&nbsp;
            <SoftTypography
              component="a"
              href="javascript:void(0)"
              variant="caption"
              color="secondary"
              title="Upload logo"
              fontWeight="medium"
              onClick={() => this.setState({viewstatus: 'uploadlogo',selected_group:thisid})}
            >
              <FaImage></FaImage> 
            </SoftTypography>

            

            </SoftBox>
            
          ),
          'id' : res[iloop].id
        };
      }
      else  //not admin
      {
         arrayLike = {
        groupname: (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={team2} alt={res[iloop].groupname} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography component="a" href={href_link}  variant="button" fontWeight="medium" color="info">
        {res[iloop].groupname}
        </SoftTypography>
        
      </SoftBox>
    </SoftBox>
),
        location: <Function job={res[iloop].location}  />,
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        'createdon': res[iloop].createdon,
        'action' : (
          <SoftBox>
            {/*
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => this.deleteRow(thisid)}
          >
            View&nbsp;&nbsp;
            </SoftTypography>*/}

          
          </SoftBox>
          
        ),
        'id' : res[iloop].id
      };
      }//arrayLike

      Array.prototype.push.call(plainObj, arrayLike);

  }
  
    //const myObjStr = JSON.stringify(plainObj);
    console.log("obj:")
    console.log(plainObj)
    console.log("res:")
    console.log(res)
    
    return plainObj;
  }

  
  


  render() {  

    const handleClose = () => {
      this.setState({showModal: false});
    }
    const handleShow = () => {
      this.setState({showModal: true});
    }

    if(this.state.loggedIn!=='1')
    {
     // return <Navigate to="/sign-in" />
  
    };

  const { columns, rows } = this.groupsTableData;
  //const [list, setList] = useState([]);
  //const [actuallist, setActuallist] = useState([]);

    console.log("sess_data:");  
    console.log(this.state.loggedIn);  

    return (  
      <DashboardLayout>
      <Helmet>
      <title>My Groups | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
        {
                (() => {
                  if(this.state.viewstatus === 'list' ){ 
                   return (
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">My Groups</SoftTypography>
              

              {
                  //  alert(this.state.main_auction_details.pending_count)
                  (() => {
                    let link_to_update = global.config.app.backend_api_url + `admin/users`;
                            return (
                              <a href={link_to_update}>
                              <SoftButton variant="gradient" size="small" color="info" >
                                Update Users
                              </SoftButton></a>
                            )
                    
                  })()
                }

              
              <SoftButton variant="gradient" size="small" color="info"  onClick={() => this.setState({viewstatus: 'create',selected_group:''})}>
                Add a new Group
              </SoftButton>
              
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={this.state.posts} />
            </SoftBox>
          </Card>
        )}
        else if(this.state.viewstatus === 'uploadlogo' )
        { 
          return (
            <Card>

<SoftBox p={3} mb={1} textAlign="center">
<SoftTypography variant="h5" fontWeight="medium">
  Change Group Logo
</SoftTypography>
</SoftBox>

<SoftBox pt={2} pb={3} px={3}>
<SoftBox component="form" role="form" id="groupform">
  
<SoftBox mb={2}>
   <SoftInput type="file" name="g_group_logo_img" id="g_group_logo_img" placeholder="Profile pic" onChange={this.onImageChange}  />

 
  </SoftBox>
  
</SoftBox>

<Link to="/groups">
      
        <SoftTypography
          variant="button"
          fontWeight="medium"
          color="dark"
          onClick={() => this.setState({viewstatus: 'list',selected_group:''})}
        >
          Back 
        </SoftTypography>
      
    </Link>
   

</SoftBox>

</Card>
          )
        }
        else if(this.state.viewstatus === 'create' )
        { 


          let group_caption='New Group';
          let group_save_btn = 'Create';
          if(this.state.selected_group !='')
          {
            group_caption = 'Edit Group';
            group_save_btn  ='Update';
          }
      const post_data = { group_id: this.state.selected_group };
      
      
      
      
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
    axios.post( global.config.app.backend_api_url + `groups/getbyid`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data.group_data)
        //alert(res.data.link_data[0].invite_link)
        document.getElementById("group_name").value = res.data.group_data[0].groupname;
        document.getElementById("location").value = res.data.group_data[0].location;
        document.getElementById("main_telegram_chat_id").value = res.data.group_data[0].main_telegram_chat_id;
        document.getElementById("guest_telegram_chat_id").value = res.data.group_data[0].guest_telegram_chat_id;
        

      });


         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {group_caption}
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <SoftInput placeholder="Name" name="group_name" id="group_name" inputProps={{ maxLength: 60}}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="text" name="location" id="location" placeholder="Location" inputProps={{ maxLength: 100}} />
            </SoftBox>

            <SoftBox mb={2} style={{display:'none'}}>
              <SoftInput type="text" name="main_telegram_chat_id" id="main_telegram_chat_id" placeholder="Main Telegram Group ID" inputProps={{ maxLength: 20}} />
            </SoftBox>
            <SoftBox mb={2}  style={{display:'none'}}>
              <SoftInput type="text" name="guest_telegram_chat_id" id="guest_telegram_chat_id" placeholder="Guest Telegram Group ID" inputProps={{ maxLength: 20}} />
            </SoftBox>

            

            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={this.onClickGroupSave}>
                {group_save_btn}
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/groups">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list',selected_group:''})}
                  >
                    Back to list
                  </SoftTypography>
                
              </Link>

            &nbsp;&nbsp;&nbsp;
              
            <SoftTypography
              variant="button"
              color="info"
              fontWeight="medium"
              component={Link}
              to="javascript:void(0)"
              onClick={() => this.generateTelegramcode('GM')}
              textGradient
            >
              Enable Telegram For Main Group ?
            </SoftTypography>


            &nbsp;&nbsp;&nbsp;
              
            <SoftTypography
              variant="button"
              color="info"
              fontWeight="medium"
              component={Link}
              to="javascript:void(0)"
              onClick={() => this.generateTelegramcode('GG')}
               textGradient
            >
              Enable Telegram For Guest Group ?
            </SoftTypography>

            &nbsp;&nbsp;&nbsp;
              
            <SoftTypography
              variant="button"
              color="info"
              fontWeight="medium"
              component={Link}
              to="javascript:void(0)"
              onClick={() => this.generateWhatsappcode('WM')}
               textGradient
            >
              Enable Whatsapp Notification ?
            </SoftTypography>
            &nbsp;&nbsp;&nbsp;
            {/*  
            <SoftTypography
              variant="button"
              color="info"
              fontWeight="medium"
              component={Link}
              to="javascript:void(0)"
              onClick={() => this.generateWhatsappcode('WG')}
               textGradient
            >
              Enable Whatsapp For Guest Group ?
            </SoftTypography>
            */}
        </SoftBox>

<SoftBox pt={2} pb={3} px={3}>
        
<SoftBox id="whatsapp_div" style={{display:'none'}} >
            <SoftBox mb={2}>
          
              <SoftTypography
              fontWeight="medium"
            >
              To enable Whatsapp App notifications, Kindly send an email to <a style={{color:'#17c1e8'}} href="mailto:support@4cr.in" target="blank" >support@4cr.in</a> 
              &nbsp;with your details.
              <br/>

            </SoftTypography>

            <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
            Main Group Group ID
            <FormSelect size="sm" className="mb-9" name="wa_main_chat_id" id="wa_main_chat_id">
          {
                  (() => {
                  const listitems = this.state.available_whatsapp_groups;
                  //console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {

                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  rows.push(<option value={listitems[i].id}>{listitems[i].name} ({listitems[i].id})</option>);
                  
              }
              return rows;
              //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }

      </FormSelect>

            </SoftBox>
            <SoftBox mb={2}>
            Guest Group Group ID            
              <FormSelect size="sm" className="mb-9" name="wa_guest_chat_id" id="wa_guest_chat_id">
          {
                  (() => {
                  const listitems = this.state.available_whatsapp_groups;
                  //console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {

                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  rows.push(<option value={listitems[i].id}>{listitems[i].name} ({listitems[i].id})</option>);
                  
              }
              return rows;
              //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }

      </FormSelect>

            </SoftBox>


            <SoftBox mb={2}>
            Check Registration Whatsapp Group        
              <FormSelect size="sm" className="mb-9" name="wa_check_members_group" id="wa_check_members_group">
          {
                  (() => {
                  const listitems = this.state.available_whatsapp_groups;
                  //console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {

                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  rows.push(<option value={listitems[i].id}>{listitems[i].name} ({listitems[i].id})</option>);
                  
              }
              return rows;
              //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }

      </FormSelect>

            </SoftBox>


            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={this.onClickGroupWAUpdate}>
              Update
              </SoftButton>
            </SoftBox>
            
          </SoftBox>


            </SoftBox>
</SoftBox>
</SoftBox>

<SoftBox id="telegram_div_demo" style={{display:'none'}} pt={2} pb={3} px={3}>
<SoftBox  >
            <SoftBox mb={2}>
          
              <SoftTypography
              fontWeight="medium"
            > 
        Secret Code : {this.state.telegramsecret}<br/>
        
        To enable Telegram App notifications, follow these steps:<br/>
        
        1. Add the <a style={{color:'#17c1e8'}} href="http://t.me/FourCRIN_bot" target="blank" >@4cr.in</a> bot to your group.<br/>
        <img src={step1} /><br/>
        2. Give it admin rights.<br/>
        <img src={step2} width="50%" /><br/><br/>
        <img src={step3} /><br/>
        3. You will see a secret code on the 4cr.in group page.<br/>
        4. Copy that code and send it as a message to your Telegram group.<br/>
        <img src={step4} width="50%" /><br/>
        5. You're done!<br/>
        

                        </SoftTypography>

  </SoftBox>
  </SoftBox>
  </SoftBox>



          </Card>
        
        )
        }

        else if(this.state.viewstatus === 'edit' )
        { 
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Update Group 
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              Group Name
              <SoftInput placeholder="Name" name="group_name" id="group_name" inputProps={{ maxLength: 60}}  />
            </SoftBox>
            <SoftBox mb={2}>
              Location
              <SoftInput type="text" name="location" id="location" placeholder="Location" inputProps={{ maxLength: 100}} />
            </SoftBox>
            <SoftBox mb={2}>Upload Logo
              <SoftInput type="file" name="group_logo" id="group_logo" placeholder="Upload Logo"  />
            </SoftBox>

            <SoftBox mb={2}>Fetch Telegram Main Group Name
              <SoftInput type="text" name="main_telegram_chat_id" id="main_telegram_chat_id" placeholder="Fetch Telegram Main Group Name"  inputProps={{ maxLength: 30,readOnly:true}} />
            </SoftBox>
            <SoftBox mb={2}>Fetch Telegram Guest Group Name
              <SoftInput type="text" name="guest_telegram_chat_id" id="guest_telegram_chat_id" placeholder="Fetch Telegram Guest Group Name"  inputProps={{ maxLength: 30,readOnly:true}} />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={() => this.setState({viewstatus: 'list'})}>
                Update
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/groups">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list',selected_group:''})}
                  >
                    Back to list
                  </SoftTypography>
                
              </Link>

        </SoftBox>

          </Card>
        
        )
        }
        
      })()
        
        }
        </SoftBox>
        
      </SoftBox>
      
      <Modal
        isOpen={this.state.showModalTelegram}
        onRequestClose={this.closeModalTelegram}
        style={customStyles}
        contentLabel="Modal"
      >
        <h2 >How to enable Telegram notification ?</h2>
        
        <button variant="button" fontWeight="medium" color="info" onClick={this.closeModalTelegram}>close</button>
        <div>
        
        To enable Telegram App notifications, follow these steps:<br/>
        
        1. Add the <a style={{color:'#17c1e8'}} href="http://t.me/FourCRIN_bot" target="blank" >@4cr.in</a> bot to your group.<br/>
        2. Give it admin rights.<br/>
        3. You will see a secret code on the 4cr.in group page.<br/>
        4. Copy that code and send it as a message to your Telegram group.<br/>
        5. You're done!<br/>
        </div>
        
      </Modal>

      <Footer />
      
    </DashboardLayout>
    )  
  }  
}  

